.selectCur {
    display: flex;
    width: 100%;
    max-width: 606px;
    margin: auto;
    align-items: flex-start;
    justify-content: center;
    flex-wrap: wrap;
}

.selectCur > div:last-child {
    margin-right: 0;
}

body[dir=rtl] .selectCur > div:last-child {
    margin-left: 0;
}

.sections.sec1,
.sections.sec2,
.sections.sec3,
.sections.sec4 {
    position: relative;
    width: 28%;
    margin-right: 10px;
}

body[dir=rtl] .sections.sec1,
body[dir=rtl] .sections.sec2,
body[dir=rtl] .sections.sec3,
body[dir=rtl] .sections.sec4  {
    margin-right: 0;
    margin-left: 10px;
}

.sections.sec3 {
    top: 1px;
    width: 46px;
    outline: 1px solid white;
    background: transparent;
}

.sections.sec1 input,
.sections.sec2 > div:only-child,
.sections.sec4 > div:only-child {
    font-size: 20px;
    width: 100%;
    height: 46px;
    text-align: center;
    border: none;
}

.sections.sec4 .css-1hwfws3,
.sections.sec2 .css-1hwfws3 {
    padding: 0;
}

.sections.sec2 > div > div > div:first-child,
.sections.sec4 > div > div > div:first-child {
    display: flex;
    align-items: center;
}

.sections [tabindex='-1'] {
    padding-top: 3px;
    padding-right: 0;
    padding-bottom: 3px;
    padding-left: 0;
    background-color: white;
}

.sections.sec2 > div:last-child > div:only-child,
.sections.sec4 > div:last-child > div:only-child {
    height: 150px;
}

.sections.sec2 > span + div + div,
.sections.sec2 > div + div,
.sections.sec4 > span + div + div,
.sections.sec4 > div + div {
    height: 160px;
    margin-top: 0;
    border-radius: 0;
    box-shadow: none;
}

.sections.sec2 > div:last-child > div:only-child > div:hover,
.sections.sec4 > div:last-child > div:only-child > div:hover {
    cursor: pointer;
    background-color: #cce3ff;
}

.sections.sec4 > span + div,
.sections.sec2 > span + div,
.sections.sec4 > div,
.sections.sec2 > div {
    height: 46px;
    border-color: #fff;
    border-radius: 0;
    box-shadow: none;
}

.sections.sec2 > span + div > div > div:first-child,
.sections.sec4 > span + div > div > div:first-child {
    opacity: 1;
}

.sections.sec2 > span + div > div:first-child > div:last-child,
.sections.sec4 > span + div > div:first-child > div:last-child {
    opacity: 1;
}

.sections.sec2 > span + div > div > div >div,
.sections.sec4 > span + div > div > div >div {
    margin: 0 10px;
    color: black !important;
}

.sections.sec2 > div:last-child > div:only-child > div,
.sections.sec4 > div:last-child > div:only-child >div {
    display: flex;
    align-items: center;
}

.sections .flag_24 + span {
    font-weight: 500;
    color: #000;
}

@media (max-width: 930px) {
    .sections.sec2 > div:last-child > div:only-child,
    .sections.sec4 > div:last-child > div:only-child {
        height: 110px;
    }

    .sections.sec2 > span + div + div,
    .sections.sec2 > div + div,
    .sections.sec4 > span + div + div,
    .sections.sec4 > div + div {
        height: 115px;
    }

    .sections .flag_24 + span {
        font-size: 13px;
    }
}

@media (max-width: 520px) {
    .sections.sec1 input {
        width: 100%;
    }
}