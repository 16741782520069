body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.selectCur {
    display: flex;
    width: 100%;
    max-width: 606px;
    margin: auto;
    align-items: flex-start;
    justify-content: center;
    flex-wrap: wrap;
}

.selectCur > div:last-child {
    margin-right: 0;
}

body[dir=rtl] .selectCur > div:last-child {
    margin-left: 0;
}

.sections.sec1,
.sections.sec2,
.sections.sec3,
.sections.sec4 {
    position: relative;
    width: 28%;
    margin-right: 10px;
}

body[dir=rtl] .sections.sec1,
body[dir=rtl] .sections.sec2,
body[dir=rtl] .sections.sec3,
body[dir=rtl] .sections.sec4  {
    margin-right: 0;
    margin-left: 10px;
}

.sections.sec3 {
    top: 1px;
    width: 46px;
    outline: 1px solid white;
    background: transparent;
}

.sections.sec1 input,
.sections.sec2 > div:only-child,
.sections.sec4 > div:only-child {
    font-size: 20px;
    width: 100%;
    height: 46px;
    text-align: center;
    border: none;
}

.sections.sec4 .css-1hwfws3,
.sections.sec2 .css-1hwfws3 {
    padding: 0;
}

.sections.sec2 > div > div > div:first-child,
.sections.sec4 > div > div > div:first-child {
    display: flex;
    align-items: center;
}

.sections [tabindex='-1'] {
    padding-top: 3px;
    padding-right: 0;
    padding-bottom: 3px;
    padding-left: 0;
    background-color: white;
}

.sections.sec2 > div:last-child > div:only-child,
.sections.sec4 > div:last-child > div:only-child {
    height: 150px;
}

.sections.sec2 > span + div + div,
.sections.sec2 > div + div,
.sections.sec4 > span + div + div,
.sections.sec4 > div + div {
    height: 160px;
    margin-top: 0;
    border-radius: 0;
    box-shadow: none;
}

.sections.sec2 > div:last-child > div:only-child > div:hover,
.sections.sec4 > div:last-child > div:only-child > div:hover {
    cursor: pointer;
    background-color: #cce3ff;
}

.sections.sec4 > span + div,
.sections.sec2 > span + div,
.sections.sec4 > div,
.sections.sec2 > div {
    height: 46px;
    border-color: #fff;
    border-radius: 0;
    box-shadow: none;
}

.sections.sec2 > span + div > div > div:first-child,
.sections.sec4 > span + div > div > div:first-child {
    opacity: 1;
}

.sections.sec2 > span + div > div:first-child > div:last-child,
.sections.sec4 > span + div > div:first-child > div:last-child {
    opacity: 1;
}

.sections.sec2 > span + div > div > div >div,
.sections.sec4 > span + div > div > div >div {
    margin: 0 10px;
    color: black !important;
}

.sections.sec2 > div:last-child > div:only-child > div,
.sections.sec4 > div:last-child > div:only-child >div {
    display: flex;
    align-items: center;
}

.sections .flag_24 + span {
    font-weight: 500;
    color: #000;
}

@media (max-width: 930px) {
    .sections.sec2 > div:last-child > div:only-child,
    .sections.sec4 > div:last-child > div:only-child {
        height: 110px;
    }

    .sections.sec2 > span + div + div,
    .sections.sec2 > div + div,
    .sections.sec4 > span + div + div,
    .sections.sec4 > div + div {
        height: 115px;
    }

    .sections .flag_24 + span {
        font-size: 13px;
    }
}

@media (max-width: 520px) {
    .sections.sec1 input {
        width: 100%;
    }
}
/*.tab-bar {*/
    /*height: 48px;*/
    /*display: flex;*/
    /*justify-content: center;*/
    /*align-items: stretch;*/
/*}*/

.tab-panel {
    margin-top: 1rem;
}

.tab-bar {
    display: flex;
    justify-content: center;
    align-items: stretch;
    position: relative;
    padding-bottom: 30px;
    /*float: right;*/
}
.tab {
    color: black;
    cursor: pointer;
    font-family: 'Montserrat';
    font-weight: 500;
    font-size: 16px;
    color: #9b9b9b;
    margin: 0 25px;
    padding-bottom: 3px;
}

.tab--active {
    color: black;
    margin: 0 25px;
    border-bottom: 2px solid #4C7B00;
}

.calc_calc{
    margin: 0 25px;
    padding-bottom: 3px;
}
.calc_calc.active{
    border-bottom: 2px solid #4C7B00;
}
.nt_mt_tabs{
    display: flex;
    justify-content: center;
    margin-bottom: 40px;
}
/*.tab:hover {*/
    /*color: white;*/
    /*border-bottom: 3px solid #98cb45;*/
/*}*/
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

::-webkit-scrollbar {
    width: 6px;
}

::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0);
    border-radius: 0;
    background: #f1f1f1;
}

::-webkit-scrollbar-thumb {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0);
    background: #CCCCCC;
    height: 80px;
}

::-webkit-input-placeholder,
::-ms-input-placeholder,
::-webkit-input-placeholder,
*::-webkit-input-placeholder {
    font-family: 'Montserrat';
    font-size: 20px;
    font-weight: normal;
    line-height: normal;
    color: #9b9b9b;
}

::-webkit-input-placeholder,
::-ms-input-placeholder,
::placeholder,
*::placeholder {
    font-family: 'Montserrat';
    font-size: 20px;
    font-weight: normal;
    line-height: normal;
    color: #9b9b9b;
}

body {
    font-family: 'Montserrat';
    font-weight: normal;
    overflow-x: hidden;
    font-size: 16px;
}

a {
    color: #2f5a8d;
    text-decoration: none;
    -webkit-text-decoration-line: none;
            text-decoration-line: none;
    -webkit-text-decoration-style: solid;
            text-decoration-style: solid;
    -webkit-text-decoration-style: initial;
            text-decoration-style: initial;
    -webkit-text-decoration-color: inherited;
            text-decoration-color: inherited;
    -webkit-text-decoration-color: initial;
            text-decoration-color: initial;
}

.row {
    display: flex;
    flex-wrap: wrap;
}

input:focus,
input:active,
button:focus,
button:active,
select:focus,
select:active,
option:focus,
option:active,
textarea:active,
textarea:focus {
    outline: none;
}

input,
select,
button,
textarea {
    border: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;

}

input[type="number"] {
    -webkit-appearance: textfield;
    -moz-appearance: textfield;
    appearance: textfield;
}

textarea {
    resize: none;
}

button:hover {
    cursor: pointer;
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    margin: 0;
}

.dir-ltr{
    direction: ltr !important;
}

h1 {
    font-size: 27px;
    color: white;
    font-weight: normal;
}

h2 {
    font-size: 20px;
    font-weight: 500;
    margin-bottom: 20px;
}

h2.white_h2 {
    color: #8dc2ff;
    font-size: 14px;
}

h5 {
    font-weight: bold;
}


li {
    list-style: none;
}
/* ----------------------- HEADER -----------------------*/

header {
    position: relative;
    margin-bottom: 40px;
}
@media (min-width: 576px) {
    .site-header {
        background-image: url(/assets/img/currency_converter_header.jpg);
        background-position: center;
    }
}
.site-header {
    position: relative;
    opacity: .96;
    background-color: #045ac0;
}

.navbar  .container-fluid {
    display: flex;
    margin-bottom: 45px;
    padding: 30px 30px 0;
    justify-content: space-between;
    align-items: center;
}

.cutout {
    position: absolute;
    bottom: -5px;
    width: 100%;
    height: 74px;
    background: url(/assets/img/headerarrow.svg) center;
    background-size: cover;
}

.clear {
    clear: both;
}

.container-fluid > .navbar-header {
    position: relative;
}

.nav > li {
    position: relative;
    display: flex;
    float: left;
    margin-right: 20px;
    justify-content: space-between;
    align-items: center;
}

body[dir=rtl] .nav > li {
    float: right;
}

.nav > li:nth-last-child(2) {
    margin-right: 0;
}

body[dir=rtl] .nav > li:nth-last-child(2) {
    margin-left: 0;
}

.pull-right li.active,
.pull-right li:not(.flag):hover {
    border-bottom: 2px solid #fff;
}

.nav > li > a {
    font-family: 'Montserrat';
    font-size: 14px;
    line-height: normal;
    position: relative;
    display: block;
    padding-bottom: 10px;
    letter-spacing: normal;
    color: #fff;
}

.currency_box {
    width: 100%;
    max-width: 920px;
    margin: 0 auto;
    padding-bottom: 100px;
}

.left_box {
    width: 100%;
}

.h1_title {
    font-weight: bold;
    text-align: center;
}

.p2 {
    font-size: 18px;
    font-weight: normal;
    margin: 10px 0 30px;
    color: white;
    text-align: center;
}

.select_div {
    display: flex;
    width: 100%;
    max-width: 606px;
    margin: auto;
    margin-bottom: 30px;
    align-items: flex-start;
    justify-content: center;
    flex-wrap: wrap;
}

.select_text {
    font-size: 24px;
    width: 100%;
    text-align: center;
    color: white;
    margin-bottom: 10px;
}

.select_text #fz {
    font-size: 40px;
}

.p2-currency .select_text span {
    font-family: Roboto;
    font-size: 16px;
    letter-spacing: .8px;
}

.p2-currency .select_text .cur2,
.p2-currency .select_text .cur1,
.select_text .equal,
.select_text .slash {
    color: #aac4e2;
}

.p2-currency .select_text .amount,
#fz .cur2-color {
    color: white;
}


@media (max-width: 475px) {
    .select_text #fz {
        font-size: 26px;
    }

    .select_text {
        font-size: 18px;
    }
}

/* ----------------------- END HEADER -----------------------*/


/* ----------------------- CONTENT WITH FORM -----------------------*/

.content {
    position: relative;
    width: 1140px;
    margin: 0 auto;
}

.open_trd_acc {
    width: 100%;
    margin-bottom: 45px;
    padding-bottom: 50px;
    border-bottom: 1px solid #ccc;
}

.open_trd_acc h2 {
    font-size: 24px;
    font-weight: normal;
    width: 100%;
    margin-bottom: 60px;
    text-align: center;
}

.trd_acc_left_box {
    width: 50%;
    padding-left: 40px;
}

body[dir=rtl] .trd_acc_left_box {
    padding-left: 0;
    padding-right: 40px;
}

.trd_acc_right_box {
    width: 50%;
    padding-right: 40px;
}

body[dir=rtl] .trd_acc_right_box {
    padding-left: 40px;
    padding-right: 0;
}

.trd_acc_head {
    margin-bottom: 32px;
    flex-wrap: nowrap;
}

.title_acc_head {
    font-size: 16px;
    font-weight: normal;
    line-height: 1.63;
    display: flex;
    flex-direction: column;
    padding-left: 40px;
    text-align: left;
    justify-content: center;
}

body[dir=rtl] .title_acc_head {
    padding-right: 40px;
    padding-left: 0;
    text-align: right;
}
.landing .title_acc_head{
    padding: 0 !important;
}

.title_acc_head > span:first-child {
    font-size: 20px;
    font-weight: 500;
    color: #000;
}

.ul-tick li {
    font-size: 16px;
    font-weight: normal;
    line-height: 44px;
    position: relative;
    padding-left: 35px;
    text-align: left;
}

body[dir=rtl] .ul-tick li {
    padding-right: 35px;
    padding-left: 0;
    text-align: right;
}

.ul-tick li:before {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 18px;
    height: 18px;
    margin: auto;
    content: '';
    -webkit-transition: .2s;
    transition: .2s;
    background: url(/assets/img/arrows_sprite.png) -58px -120px no-repeat;
}

body[dir=rtl] .ul-tick li:before {
    right: 0;
    left: auto;
    content: '';
}

.form-group {
    font-size: 14px;
    position: relative;
    margin-bottom: 20px;
}

label.field {
    position: absolute;
    top: -10px;
    left: 15px;
    background: white;
    z-index: 100;
    padding: 1px 5px;
}

.trd_acc_right_box .field input {
    position: relative;
    width: 100%;
    border-radius: 0;
    background-color: #f7f7f7;
}

.form-control {
    font-size: 14px;
    font-weight: 500;
    display: block;
    width: 100%;
    height: 40px;
    padding: 0 10px;
    -webkit-transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
    transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
    color: #495057;
    border: 1px solid #ccc;
    border-radius: 4px;
}

.errortext {
    font-size: 12px;
    text-align: right;
    color: red;
}

body[dir=rtl] .errortext {
    text-align: left;
}

.trd_acc_right_box .field > div > div:last-of-type {
    text-align: left;
    border-radius: 0;
    background-color: #f7f7f7;
}

body[dir=rtl] .trd_acc_right_box .field > div > div:last-of-type {
    text-align: right;
}

.trd_acc_right_box .field > div > div:first-of-type {
    border: 1px solid #ccc;
    border-radius: 0;
    background-color: #f7f7f7;
    box-shadow: none;
}

body[dir="rtl"] .react-tel-input {
    direction: ltr;
}

.react-tel-input input[type=tel] {
    height: 40px;
    width: 100%;
}

.trd_acc_right_box .react-tel-input .selected-flag {
    z-index: 0;
}

.trd_acc_right_box .react-tel-input .selected-flag .arrow {
    display: none;
}

#wants_to_subscribe_corporate .field {
    margin-left: 0;
}

body[dir=rtl] #wants_to_subscribe_corporate .field {
    margin-right: 0;
}

#wants_to_subscribe_corporate {
    margin: 20px 0;
    justify-content: flex-end;
}

#wants_to_subscribe_corporate .input {
    display: flex;
}

#wants_to_subscribe_corporate .input input:before {
    position: absolute;
    top: 0;
    display: inline-block;
    width: 20px;
    height: 20px;
    content: '';
    border: solid 1px #ccc;
    background-color: #f7f7f7;
}

#wants_to_subscribe_corporate .input input:after {
    position: absolute;
    top: 6px;
    left: 5px;
    display: inline-block;
    width: 10px;
    height: 5px;
    -webkit-transform: rotate(-45deg);
            transform: rotate(-45deg);
    border-bottom: 2px solid #999;
    border-left: 2px solid #999;
}

body[dir=rtl] #wants_to_subscribe_corporate .input input:after {
    right: 5px;
    left: auto;
    border-right: 2px solid #999;
    border-left: none;
}

#wants_to_subscribe_corporate .input input[type=checkbox]:checked:after {
    content: '';
}

#wants_to_subscribe_corporate .input_info {
    display: table;
    height: 22px;
    padding-bottom: 0;
    padding-left: 42px;
    text-align: left;
}

body[dir=rtl] #wants_to_subscribe_corporate .input_info {
    padding-right: 42px;
    padding-left: 0;
    text-align: right;
}

#wants_to_subscribe_corporate .input_info label {
    font-size: 14px;
    font-weight: normal;
    display: table-cell;
    vertical-align: middle;
    text-transform: none;
    text-transform: initial;
}

.field button {
    width: auto;
    min-width: 200px;
    border-radius: 4px;
}

/* ----------------------- END CONTENT WITH FORM -----------------------*/




/* ----------------------- GRAPHIC BLOCK-----------------------*/

.my_wrapper {
    display: flex;
    flex-wrap: wrap;
}

.curr_cont {
    display: flex;
    width: 100%;
    flex-wrap: wrap;
}

.one {
    width: calc(100% - 250px);
    padding-right: 70px;
}

body[dir=rtl] .one {
    padding-right: 0;
    padding-left: 70px;
}

.two {
    width: 100%;
    max-width: 250px;
}

.chart_content {
    margin-bottom: 50px;
}

body[dir='rtl'] .chart_content h2 {
    text-align: right;
}

.chart_content h2{
    margin-bottom: 20px;
}

.Apper {
    padding-top: 25px;
    position: relative;
}

.Apper > button {
    width: 25px;
    margin: 5px;
    border-radius: 5px;
}

.Apper > button:hover {
    background-color: #505edf;
}

.Apper > .intervalButton {
    display: flex;
    float: right;
    position: absolute;
    top: 0;
    right: 0;
}

body[dir=rtl] .Apper > .intervalButton {
    left: 0;
    right: auto;
}

@media(max-width: 550px) {
    .Apper > .intervalButton,
    body[dir=rtl] .Apper > .intervalButton{
        left: 0;
        right: 0;
        justify-content: center;
    }
}

.Apper > .intervalButton > button {
    font-size: 14px;
    width: 30px;
    height: 30px;
    margin: 5px;
    padding: 5px;
    color: #3d77bb;
    border-radius: 5px;
    background-color: white;
}

.Apper > .intervalButton > button:hover {
    color: white;
    background-color: #3d77bb;
}

.see {
    padding: 30px 0 0 0;
    background-color: white;
}

.buy_sell {
    width: 100%;
}

.buy_sell h2 {
    font-family: Montserrat;
    font-size: 20px;
    font-weight: normal;
    margin-bottom: 24px;
}
.initial-amount{
    display: flex;
    flex-direction: column-reverse;
}
.calculation-base{
    display: flex;
    align-items: center;
}
.calculation-base > div{
    margin:  0 5px;
}
.calculated-amount.green{
    color: #4C7B00;
}
.calculated-amount.red{
    color: #dc3545;
}
.btn-danger{
    background: #dc3545;
}
.calculated-amount sup{
    font-size: 20px;
    margin-top: -20px;
}
.mx-10{
    margin-left: 5px;
    margin-right: 5px;
}
.buy-sel-comp .btn{
    color: white;
    padding: 15px;
    width: 100%;
    max-width: 250px;
    height: auto !important;
    height: initial !important;
    line-height: normal !important;
    line-height: initial !important;
}

.btn-success{
    background: #4C7B00;
}

.btn-trans{
    background: transparent;
    color: #49536e !important;
}
.mb-10{
    margin-bottom: 10px;
}

.mb-20{
    margin-bottom: 20px;
}
.mb-30{
    margin-bottom: 30px;
}
.text-center{
    text-align: center;
}

.display_of_button {
    width: 100%;
}
.display_of_button .buy-item,
.display_of_button .sell-item{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 100%;
    height: 110px;
    padding: 10px;
    font-size: 1.875rem;
    color: white;
}
.display_of_button .sell-item{
    background: #dc3545;
}
.display_of_button .buy-item{
    background: #4C7B00;
}
.display_of_button span.mb-10{
    font-weight: bold;
}
.display_of_button .inst {
    font-size: 1.25rem;
}

.b_s_p {
    float: left;
    box-sizing: border-box;
    width: 33.33333333333%;
    text-align: center;
}

.cur_cur {
    padding: 6px;
    color: #000;
    background: #f6f6f6;
}

.button_dark_red,
.button_dark_green {
    font-size: 16px;
    font-weight: normal;
    display: inline-block;
    padding: 0 0;
    -webkit-transition: .4s;
    transition: .4s;
    text-align: center;
    color: #fff;
    border: 0;
    border-radius: 0;
}

.button_dark_red {
    -webkit-transition: .4s;
    transition: .4s;
    text-align: center;
    color: #fff;
    background: #d4171f;
}

.button_dark_green {
    -webkit-transition: .4s;
    transition: .4s;
    text-align: center;
    color: #fff;
    background: #288635;
}

.opacity_div {
    box-sizing: border-box;
    width: 100%;
    padding: 6px;
    text-align: center;
}

.bt {
    font-size: 32px;
    float: left;
    box-sizing: border-box;
    width: 50%;
    padding: 7px;
    text-align: center;
}

.current_instrument_bid {
    color: #d4171f;
}

.current_instrument_ask {
    color: #32aa43;
}

.stat_changes {
    font-weight: 500;
}

.pci_status_trading,.pci_change_value {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px;
}

.pci_status_trading > span,
.pci_change_value > span {
    font-size: 14px;
}

.live_closed {
    display: none;
}

.live_closed span {
    color: #666;
}

.live_trading span {
    color: #288635;
}

.max_min {
    clear: both;
    width: 100%;
    margin-bottom: 36px;
    border-top: 1px solid #ccc;
}

.max_min_blocks {
    position: relative;
    width: 100%;
    height: 35px;
}

.max_min_blocks div {
    position: absolute;
    background: white;
}

.lbox {
    font-size: 12px;
    top: 20px;
    left: 0;
    padding: 2px 15px 2px 2px;
}

body[dir=rtl] .lbox {
    right: 0;
    left: inherit;
    padding: 2px 2px 2px 15px;
}

.rbox {
    font-size: 18px;
    top: 15px;
    right: 0;
    padding: 2px 2px 2px 15px;
}

body[dir=rtl] .rbox {
    right: inherit;
    left: 0;
    padding: 2px 15px 2px 2px;
}

.to_up {
    color: #32aa43;
}

.to_down {
    color: #d7262e;
}

/* ----------------------- END GRAPHIC BLOCK -----------------------*/



.button_outline_blue {
    border-radius: 4px;
    display: inline-block;
    text-align: center;
    color: #2f5a8d !important;
    background: transparent;
    font-size: 16px;
    font-weight: 400;
    border: 1px solid #2f5a8d !important;
    -webkit-transition: .4s;
    transition: .4s;
    padding: 11px 20px;
}

.button_outline_blue:hover {
    background: #3f79bc !important;
    color: white !important;
}

.button_green {
    background-color: #4C7B00;
    color: #2c2c2c;
    -webkit-transition: ease 0.4s;
    transition: ease 0.4s;
    font-size: 16px;
    height: 40px;
    line-height: 40px;
    padding: 0 5px;
    display: inline-block;
    text-align: center;
}

.button_green:hover {
    background: #3d6200;
}

.button_blue {
    font-weight: 400;
    background: #505edf;
    border: 1px solid #505edf;
}

.button_blue:hover {
    color: #fff;
    background: #508ed7;
    border: 1px solid #508ed7;
}

.button_gray {
    border-radius: 4px;
    text-align: center;
    color: #fff;
    -webkit-transition: .4s;
    transition: .4s;
    background: #5d6e81;
}

.button_gray:hover {
    cursor: pointer;
    background: #768699;
}

.btn {
    display: inline-block;
    text-align: center;
    text-transform: uppercase;
    cursor: pointer;
    color: #2c2c2c;
    -webkit-transition: .4s;
    transition: .4s;
    font-weight: 300;
}

.btnn {
    display: inline-block;
    text-align: center;
    text-transform: uppercase;
    cursor: pointer;
    /* color: #fff; */
    -webkit-transition: .4s;
    transition: .4s;
    font-weight: 300;
}

.btn-lg {
    font-size: 16px;
    min-width: 240px;
    height: 50px;
    line-height: 50px;
    padding: 0 30px;
}

.btn-md{
    font-size: 14px;
    min-width: 150px;
    height: 40px;
    line-height: 40px;
    padding: 0 20px;
}

.btn-sm {
    font-size: 14px;
    height: 30px;
    line-height: 30px;
    padding: 0 15px;
}

/*header*/

.pull-right {
    float: right;
    padding: 5px;
}

body[dir=rtl] .pull-right {
    float: left;
}

.mb30{
    margin-bottom: 30px;
}
.mb10{
    margin-bottom: 10px;
}


.loading {
    width: 50px;
    height: 50px;
    margin: 20px auto;
    border: 4px solid #f3f3f3;
    border-top: 4px solid #ff5722;
    border-radius: 50%;
    -webkit-animation: spin 1.5s linear infinite;
            animation: spin 1.5s linear infinite;
}

@-webkit-keyframes spin {
    0% {
        -webkit-transform: rotate(0deg);
                transform: rotate(0deg);
    }

    100% {
        -webkit-transform: rotate(360deg);
                transform: rotate(360deg);
    }
}

@keyframes spin {
    0% {
        -webkit-transform: rotate(0deg);
                transform: rotate(0deg);
    }

    100% {
        -webkit-transform: rotate(360deg);
                transform: rotate(360deg);
    }
}

.currency_box.pdb {
    padding-bottom: 120px;
}

.size_900 .currency_symbols > div {
    font-size: 18px;
    margin: 0 0 20px;
}

.cfd-logo {
    background: url(/assets/img/cfd-rates-logo.png);
    width: 228px;
    height: 50px;
}

.cfd-logo-mobile {
    background: url(/assets/img/cfd-rates-logo.png);
    width: 154px;
    height: 35px;
    background-size: 154px;
}

.mobile_menu {
    width: 16px;
    height: 16px;
    background: url(/assets/img/arrows_sprite.png) -24px -33px;
    display: inline-block;
}

.pull-right li:last-child {
    display: none;
}

.mb_menu {
    position: fixed;
    top: 0;
    width: 270px;
    height: 100vh;
    background-color: #fff;
    box-shadow: 0 0 9px 0 rgba(0, 0, 0, .5);
    z-index: 100;
    -webkit-transition: ease .4s;
    transition: ease .4s;
}

.mb_active {
    right: -280px;
}

.mb_none_active {
    right: 0;
}

body[dir=rtl] .mb_none_active {
    right: auto;
    right: initial;
    left: 0;
}

.menu_header {
    display: flex;
    justify-content: space-between;
    padding: 20px;
    font-size: 14px;
    font-weight: bold;
}

.menu_header div:last-child {
    color: #ff084f;
}

.menu_header div:first-child {
    color: #3e78bb;
}

.X {
    width: 20px;
    height: 20px;
    background: url(/assets/img/arrows_sprite.png) -4px -124px;

}

.menu_one.active, .menu_one:hover {
    background: #3e78bb;
}

.menu_one a {
    padding: 15px 20px;
    display: block;
    font-size: 14px;
}

.menu_one {
    border-bottom: 1px solid #d8d8d8;
}

.menu_one.active a, .menu_one:hover a {
    color: #fff;
}

.black_opacity {
    position: absolute;
    display: none;
    top: 0;
    width: 100%;
    height: 100vh;
    opacity: .5;
    background-color: #000;
    z-index: 50;
}

.bodyOverflow {
    overflow: hidden;
}

.black_opacity.active {
    display: block;
}

.footer {
    width: 100%;
    background: #f1f1f1;
    border-top: 1px solid #d8d8d8;
    margin-top: 60px;
}

/*bradcrumbs*/
.brdcrumbs_line {
    color: #2f5a8d;
}

.brdcrumbs_line:last-of-type {
    color: #000;
}

/*bradcrumbs END*/

@media (max-width: 520px) {
    .footer {
        margin-top: 30px;
    }
}

.m_foo {
    width: 520px;
    margin: 0 auto 40px;
    padding-top: 40px;
    box-sizing: border-box;
}

.foo_menu {
    width: 100%;
}

.foo_menu ul {
    display: flex;
    justify-content: space-around;
}

.foo_menu li {
    list-style: none;
    font-size: 14px;
}

.foo_menu li a {
    color: black;
}

.contact_us {
    width: 100%;
    font-size: 20px;
    text-align: center;
    margin: 40px 0 25px;
    font-weight: 500;
}

.inputs {
    display: flex;
    width: 100%;
    justify-content: space-between;
    flex-wrap: wrap;
}

.inputs input {
    width: 250px;
    font-size: 14px;
    padding: 11px;
    box-sizing: border-box;
    font-family: 'Montserrat';
    border-radius: 3px;
    border: solid 1px #cccccc;
}


.area {
    width: 100%;
    margin-top: 10px;
}

.area textarea {
    width: 100%;
    height: 90px;
    padding: 11px;
    box-sizing: border-box;
    font-family: 'Montserrat';
    border-radius: 3px;
    border: solid 1px #cccccc;
}

.send {
    text-align: center;
    margin: 15px 0;
}

.error {
    color: red;
}

.success {
    color: #32aa43;
    margin-bottom: 15px;
}

.send {
    width: 100%;
    text-align: center;
}

.copyright {
    width: 100%;
    text-align: center;
    padding: 10px 0;
    background: #e3e3e3;
    font-size: 14px;
}



.cutout img {
    width: 100%;
}

/*header_box*/

.all-page-head {
    text-align: center;
    margin: 0 auto;
    max-width: 722px;
}

.all-page-head h1 {
    font: 300 30px Montserrat;
    color: #fff;
    letter-spacing: 1.5px;
    margin-bottom: 20px;
}

.all-page-head span {
    font-weight: 700;
}

.link-white {
    font: 300 16px Montserrat;
    color: #fff;
}

.all-page-head .button_green {
    margin-bottom: 18px;
}


.local_href {
    font-size: 12px;
}

.open_button {
    width: 100%;
    margin-top: 15px;
}


.flag_24 {
    display: inline-block;
    width: 28px;
    height: 20px;
    background: url("https://ifccd.net/images/flags/flags_sprite.webp");
    margin: 0 10px;
    border-radius: 1px;
    min-width: 28px;
}

/*forex-calculator*/
.forex-calc {
    text-align: center;
    margin: 10px auto 40px;
}

.forex-calc h2 {
    margin-bottom: 50px;
    font: 500 24px Montserrat;
}

.forex-calc p {
    margin: 0;
    padding-bottom: 15px;
    border-bottom: 1px solid #d8d8d8;
    font: 500 18px Montserrat;
}

.platf-cont {
    margin-top: 50px;
    margin-bottom: 70px;
}

.platf-cont:last-child {
    margin-bottom: 40px;
}

.platf-block {
    padding: 25px 35px;
    border-radius: 10px;
    border: solid 2px #ebebeb;
    box-sizing: border-box;
    display: inline-block;
    cursor: pointer;
    -webkit-transition: .3s ease-in-out;
    transition: .3s ease-in-out;
}

.platf-block:hover {
    box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.2);
}

.platf-cont a:nth-of-type(1) {
    margin-right: 60px;
}

body[dir=rtl] .platf-cont a:nth-of-type(1) {
    margin-right: 0;
    margin-right: initial;
    margin-left: 60px;
}

.link-green {
    font-weight: 700;
    color: #4C7B00;
    cursor: pointer;
}

.platf-block span {
    display: inline-block;
}

.platf-icn-2 {
    background: url(/assets/img/platforms.png) no-repeat;
    background-position: 0 0;
    width: 167px;
    height: 51px;
}

.platf-ntx {
    background-position: -4px 1px;
}

.mt4-mt5 {
    background-position: -172px 0;
}

.platf-mt {
    background-position: -170px 0;
}

.platf-icn {
    background: url(/assets/img/arrows_sprite.png) no-repeat;
    background-position: -49px -68px;
    width: 36px;
    height: 51px;
}

.imposible {
    display: inline-block;
    width: 1px;
    height: 50px;
    background: #d8d8d8;
    margin: 0 30px;
}

.go_to_p_m {
    display: inline-block;
}

@media (min-width: 1160px) {
    .cfd-logo-mobile {
        display: none;
    }
}

@media (max-width: 1160px) {
    .navbar .flag {
        display: flex;
    }

    .cfd-logo {
        display: none;
    }

    .content {
        width: 100%;
        padding: 0 30px;
        box-sizing: border-box;
    }

    .content .content {
        padding: 0;
    }

    .currency_box {
        padding: 0 20px 120px 20px;
        box-sizing: border-box;
    }
}

@media (max-width: 928px) {
    .navbar .container-fluid{
        padding: 20px 20px 0;
    }
    .nav > li,
    .navbar-nav > li {
        margin-left: 10px;
        margin-right: 0;
    }

    .container-fluid {
        padding-right: 0;
        padding-left: 0;
    }

    .container-fluid {
        margin-bottom: 80px;
    }

    .currency_box {
        padding-bottom: 0;
    }
}

@media (max-width: 820px) {
    .platf-block {
        padding: 25px 10px;
    }

    .platf-cont a:nth-of-type(1) {
        margin-right: 0px;
    }

    body[dir=rtl] .platf-cont a:nth-of-type(1) {
        margin-right: 0;
        margin-right: initial;
        margin-left: 20px;
    }

    .imposible {
        margin: 0 15px;
    }

    .platf-cont {
        display: flex;
        justify-content: center;
    }

    .platf-cont a:first-child {
        margin-right: 5px;
    }

    .platf-cont a:last-child {
        margin-left: 5px;
    }

    .platf-cont:last-child {
        margin-bottom: 0px;
    }

    .forex-calc {
        margin: 0 auto;
    }
}

@media (max-width: 767px) {
    .pull-right li {
        display: none;
    }

    .pull-right li:last-child {
        display: inline-block;
    }
}

@media (max-width: 520px) {
    .foo_menu ul {
        display: block;
    }

    .foo_menu li {
        padding: 12px;
        background: #e3e3e3;
        margin-bottom: 1px;
        text-align: center;
    }

    .m_foo {
        width: 100%;
        padding-top: 0;
    }

    .inputs input:first-child {
        margin-bottom: 10px;
    }

    .inputs input {
        width: 100%;
    }

    .contact_us + form {

        padding: 0 30px;
    }
}

@media (min-width: 475px) and (max-width: 642px) {
    .imposible {
        display: none !important;
    }
}

@media (max-width: 475px) {
    .trd_acc_head{
        display: flex;
        flex-direction: column;
    }
    .trd_acc_head .title_acc_head{
        margin-top: 25px;
        padding: 0;
        align-items: center;
        text-align: center;
    }
    .platf-cont a:first-child {
        margin-right: 0;
        margin-bottom: 20px;
    }

    .platf-cont a:last-child {
        margin-left: 0px;
    }

    .platf-cont {
        display: block;
    }

    .platf-cont a {
        width: 100%;
    }

    .platf-block {
        width: 100%;
    }
}

/*[[[[[[[[[[[[[[[[[[[[[[[[[[[[[[[[[[[[CURRENCY]]]]]]]]]]]]]]]]]]]]]]]]]]]]]]]]]]]]]*/

.head_text {
    width: 100%;
    margin-bottom: 20px;
}

.head_text h2 {
    text-align: center;
    margin-bottom: 40px;
}

.currency_symbols {
    width: 100%;
    margin-bottom: 50px;
    overflow: hidden;
    padding-bottom: 10px;
}

.currency_symbols p {
    font-size: 18px;
    margin: 0 0 20px;
}

.currency_symbols ul li {
    position: relative;
    padding-left: 45px;
    margin-bottom: 5px;
    line-height: 1.63;
}

.currency_symbols > ul > li:last-child {
    padding-left: 0;
}

.currency_symbols ul li span {
    position: absolute;
    display: inline-block;
    width: 24px;
    height: 24px;
    background: url(/assets/img/arrows_sprite.png) -57px -41px;
    left: 0;
}

/*Want to earn*/

.want_to_earn {
    width: 100%;
}

.divs_p {
    margin-bottom: 50px;
}

.earn_text {
    text-align: center;
    font-size: 20px;
    max-width: 610px;
    margin-left: auto;
    margin-right: auto;
}

.earn_text span {
    color: #4C7B00;
}

.earn_button {
    margin: 50px 0;
    text-align: center;
}

.want_to_earn p {
    margin-bottom: 20px;
}

/*tab*/

.tabs {
    width: 100%;
}

.tabs table {
    width: 100%;
    border-collapse: collapse;
}

.tabs td {
    position: relative;
    padding: 10px;
}

.tabs thead tr:first-child td {
    border-bottom: 1px solid #979797;
    text-align: left;
}

.tabs tr:nth-child(2n - 1) td:first-child {
    box-sizing: border-box;
}

.tabs.__tab_tab_1 tbody tr:nth-child(2n-1) td:first-child {
    display: flex;
    align-items: center;
}

.tabs.__tab_tab_2 tbody tr td:first-child {
    display: flex;
    align-items: center;
}

.tabs.__tab_tab_2 tr td:first-child {
    box-sizing: border-box;
}

.col-sort:nth-child(1) {
    padding-left: 55px;
    width: 250px;
}

.tabs table .currensyname .flag_24 {
    position: absolute;
    left: 10px;
    top: 0;
    bottom: 0;
    margin: auto;
}


#red {
    background-color: red;
    -webkit-transition: 2s;
    transition: 2s;
    color: white;
}



.see_more_pairs {
    text-align: right;
    margin: 20px 0 10px;
}

.__our_two_tabs {
    display: flex;
    justify-content: center;
    color: #9b9b9b;
}

.__our_two_tabs .many_tabs {
    padding-bottom: 5px;
    margin-right: 35px;
    border-bottom: 2px solid transparent;
}

.__our_two_tabs .many_tabs:hover {
    color: #000000;
    cursor: pointer;
}

.many_tabs.active {
    border-bottom: 2px solid #4C7B00;
    color: #000000;
}

.result_notfound {
    display: none;
}

#search-highlight {
    width: 100%;
    padding: 13px;
    box-sizing: border-box;
    border-radius: 3px;
    background-color: #ffffff;
    border: solid 1px #cccccc;
    font-size: 16px;
}

.instrumentsearch {
    margin-bottom: 30px;
}

.search_div {
    position: relative;
}

.search_div label {
    font-size: 12px;
}

.search_icon {
    position: absolute;
    right: 15px;
    width: 20px;
    height: 20px;
    background: url(/assets/img/arrows_sprite.png) -65px -1px;
    top: 0;
    bottom: 0;
    margin: auto;
}

.line {
    position: absolute;
    width: 100%;
    height: 1px;
    background: #cccccc;
    top: 0;
    bottom: 0;
    margin: auto;
}

.arrow_down {
    position: absolute;
    left: 0;
    right: 0;
    margin: auto;
    bottom: -12px;
    width: 10px;
    height: 10px;
    background: url(/assets/img/arrows_sprite.png) -27px -122px no-repeat;
}

.__less {
    display: none;
}


body[dir='rtl'] .search_icon {
    right: inherit;
    left: 15px;
}


body[dir='rtl'] .currency_symbols ul li {
    padding-left: 0;
    padding-right: 40px;
}

body[dir='rtl'] .currency_symbols ul li span {
    left: inherit;
    right: 0;
}

body[dir='rtl'] .__our_two_tabs .many_tabs {
    margin-right: inherit;
    margin-left: 35px;
}


body[dir='rtl'] .tabs thead tr:first-child td {
    text-align: right;
}

body[dir='rtl'] .tabs table .currensyname .flag_24 {
    left: inherit;
    right: 10px;
}

body[dir='rtl'] .arrow_to_show {
    right: inherit;
    left: 3px;
}

body[dir='rtl'] .left_dropping_down {
    float: right;
}

body[dir='rtl'] .right_dropping_down {
    float: left;
}

body[dir='rtl'] .see_more_pairs {
    text-align: left;
}

body[dir='rtl'] .tabs.__tab_tab_2 tr td:first-child {
    padding-left: inherit;
    padding-right: 50px;
}

.swipe {
    width: 100%;
    text-align: center;
    margin: 20px 0;
    display: none;
}

@media (max-width: 767px) {
    /*.__our_two_tabs {*/
    /*display: block;*/
    /*}*/
    .__our_two_tabs .many_tabs {
        margin-right: 20px;
    }

    body[dir='rtl'] .__our_two_tabs .many_tabs {
        margin-left: 20px;
    }

    .many_tabs {
        margin-bottom: 3px;
        font-size: 14px;
    }

    .scroll_content {
        overflow-x: scroll;
    }

    .tabs table {
        width: 850px;
    }

    .swipe {
        display: block !important;
    }
}

@media (max-width: 656px) {
    .__our_two_tabs {
        display: block;
    }

    .__our_two_tabs .many_tabs {
        margin-right: 0;
        padding: 10px 5px;
        background: #f1f1f1;
        color: black;
        text-align: center;
        border-bottom: none;
    }

    body[dir='rtl'] .__our_two_tabs .many_tabs {
        margin-left: 0;
    }

    .many_tabs.active {
        background: #4C7B00;
        color: #ffffff !important;
    }

    .earn_button {
        margin: 30px 0 40px;
    }

    .earn_button:last-child {
        margin: 30px 0 10px;
    }

    .divs_p p {
        text-align: justify;
    }

    .instrumentsearch {
        margin-bottom: 20px;
    }
}

@media (max-width: 375px) {
    .head_text h2 {
        margin-bottom: 20px;
    }
}

#__more_ul ul {
    display: none;
}


/*/////////////////////////////////////////////////////*/

.tabs.__tab_tab_1 tbody tr:nth-child(2n-1) td:first-child {
    display: flex;
    align-items: center;
}

.tabs.__tab_tab_2 tbody tr td:first-child {
    display: flex;
    align-items: center;
}

.tabs.__tab_tab_2 tr td:first-child {
    box-sizing: border-box;
}

.col-sort:nth-child(1) {
    padding-left: 55px;
    width: 250px;
}

.__tab_tab_2 {
    display: none;
}

.tabs table .currensyname .flag_24 {
    position: absolute;
    left: 10px;
    top: 0;
    bottom: 0;
    margin: auto;
}

.calculators {
    color: #000000;
    font-weight: 500;
    text-align: center;
    font-size: 24px;
    margin: 10px 0 50px;
}

.nt_mt_tabs {
    display: flex;
    justify-content: center;
    margin-bottom: 40px;
}

.calc_calc {
    margin: 0 25px;
    padding-bottom: 3px;
}

.calc_calc.active {
    border-bottom: 2px solid #4C7B00;
}

.tab-bar .tab--active {
    color: #000;
}

.__main_form {
    margin-bottom: 60px;
}

.filer_wrap {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #cccccc;
    max-width: 695px;
    margin: 0 auto 20px;
    padding-bottom: 15px;
}

.partss select,
.bid_ask_wrapper {
    width: 224px;
    height: 36px;
    border-radius: 3px;
    border: solid 1px #cccccc;
    padding-left: 10px;
    font-size: 14px;
    background: white;
    color: #4a4a4a;
    font-weight: 500;
}

.MT4_MT5 .filer_wrap {
    max-width: 675px;
}

.partss input {
    width: 224px;
    height: 34px;
    border-radius: 3px;
    border: solid 1px #cccccc;
    padding-left: 10px;
    box-sizing: border-box;
}


.bid_ask_wrapper {
    display: flex;
    background: #f1f1f1;
    align-items: center;
    padding: 0 10px;
    box-sizing: border-box;
    justify-content: space-between;
}


.partss select option {
    padding-left: 10px;
}

.bid_ask {
    float: left;
}

.bid_ask_unit {
    float: right;
    color: #4C7B00;
}

#deal_dir_wrap {
    max-width: 695px;
    display: flex;
    margin: 0 auto 20px;
    justify-content: center;
}

.customs {
    margin: 5px 0;
    display: flex;
    align-items: center;
}


.calc_input_wrap {
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
    padding-bottom: 20px;
}

.partss {
    color: #4a4a4a;
}

.mini_label > div {
    margin-bottom: 3px;
    font-size: 12px;
    font-weight: 500;
}

@media (max-width: 510px) {
    .calc_input_wrap .mini_label {
        height: 35px;
        margin-bottom: 5px;
    }
}

.padd {
    padding: 5px;
    display: flex;
    flex-direction: column;
}

.marg_button {
    text-align: center;
    border-bottom: 1px solid #cccccc;
    max-width: 725px;
    padding-bottom: 30px;
    margin: 0 auto 25px;
}

.marg_button .button_green {
    width: 225px;
    font-size: inherit;
    color: inherit;
}

.marg_button .button_green > span, .marg_button .button_green > div {
    font-size: 16px;
    color: white;
}


.four_components {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
}

.one_component {
    margin: 0 35px;
}

.fflag {
    width: 30px;
    margin: 0 auto 10px;
}

.fflag .flag_24 {
    margin: 0;
}

.label_text {
    font-size: 12px;
    font-weight: 400;
    margin-bottom: 10px;
    text-align: center;
}

.for_margin {
    text-align: center;
    font-size: 14px;
}

.for_unit {
    position: relative;
}

.pl_vol_unit {
    position: absolute;
    display: flex;
    align-items: center;
    top: 0;
    bottom: 0;
    margin: auto;
    right: 15px;
    color: #4C7B00;
    font-size: 18px;
    font-weight: 500;
}


/*test*/
.top_to {
    position: relative;
    top: 5px;
    margin-left: 5px;
}

.calc_radio_buy {
    margin-right: 20px;
}

.customs {
    position: relative;
    padding-left: 25px;
    margin-bottom: 12px;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    font-size: 10px;
}

/* Hide the browser's default radio button */
.customs input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    top: 0;
    left: 0;
    height: 20px;
    width: 60px;
    z-index: 100;
}

/* Create a custom radio button */
.checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 20px;
    width: 20px;
    background-color: #eee;
    border-radius: 50%;
    border: 1px solid transparent;
}

/* On mouse-over, add a grey background color */
.customs:hover input ~ .checkmark {
    background-color: #ccc;
}

/* When the radio button is checked, add a blue background */
.customs input:checked ~ .checkmark {
    background: #fff;
    border: 1px solid #ccc;
}

/* Create the indicator (the dot/circle - hidden when not checked) */
.checkmark:after {
    content: "";
    position: absolute;
    display: none;
}

/* Show the indicator (dot/circle) when checked */
.customs input:checked ~ .checkmark:after {
    display: block;
}

/* Style the indicator (dot/circle) */
.customs .checkmark:after {
    top: 3px;
    left: 3px;
    width: 12px;
    height: 12px;
    border-radius: 50%;
    background-color: #4C7B00;
}

.prls {
    width: 235px;
    text-align: center;
    font-size: 12px;
}

.nd_wrap {
    border-bottom: none;
}

.nd_wrap .partss:nth-child(2) {
    margin: 0 10px;
}

.big_select {
    height: 56px !important;
    font-size: 20px !important;
}

input.big_select {
    height: 56px !important;
    color: #4a4a4a;
    font-family: 'Montserrat';
    font-weight: 500;

}

.big_button {
    height: 50px;
}

.confirm_calculate {
    text-align: center;
    color: #ff0000;
    margin: 20px 0;
}

.confirm_calculate > span, .confirm_calculate > div {
    color: #ff0000;
}

.under_label {
    margin-left: 10px;
    margin-top: 5px;
    display: inline-block;
    font-size: 12px;
    font-weight: 500;
}

.__currencies_name {
    display: flex;
    align-items: center;
}

span.label {
    margin: 20px 0 5px;
    display: block;
}

body[dir='rtl'] .partss select option {
    padding-left: 0;
    padding-right: 10px;
}

body[dir='rtl'] .partss input {
    padding-left: 0;
    padding-right: 5px;
}

body[dir='rtl'] .rate_changes table tr .flag_24 {
    margin-left: 10px;
    margin-right: 0;
}

body[dir='rtl'] .pl_vol_unit {
    right: inherit;
    left: 5px;
}


select {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none; /* remove default arrow */
}

.div_select {
    position: relative;
}

.div_select .select_icon {
    position: absolute;
    right: 15px;
    top: -3px;
    bottom: 0;
    margin: auto;
    width: 8px;
    height: 8px;
    border-bottom: 1px solid #4a4a4a;
    border-left: 1px solid #4a4a4a;
    -webkit-transform: rotate(-45deg);
            transform: rotate(-45deg);
}

body[dir='rtl'] .div_select .select_icon {
    right: inherit;
    left: 15px;
}

body[dir='rtl'] .under_label {
    margin-left: 0;
    margin-right: 10px;
}

@media (min-width: 767px) {
    .mt4 .partss select,
    .mt4 .bid_ask_wrapper {
        width: 160px;
    }
}

@media (max-width: 767px) {
    .margin_calc .filer_wrap .partss:nth-child(3) {
        width: 100%;
    }

    .MT4_MT5 .disi {
        width: 49%;
    }

    .filer_wrap {
        width: 100%;
        flex-wrap: wrap;
        /*justify-content: center;*/
    }

    .__mt4 .filer_wrap {
        max-width: inherit;
    }

    .partss {
        width: 49%;
        margin-bottom: 20px;
    }

    .partss select, .bid_ask_wrapper {
        width: 100%;
    }

    .__mt4 .disi {
        width: 49%;
    }

    .__mt4 .partss select, .__mt4 .bid_ask_wrapper {
        width: 100% !important;
    }

    .nd_wrap .partss:nth-child(2) {
        margin: 0;
    }

    .nd_wrap .partss {
        width: 75%;
        margin-bottom: 15px !important;
    }

    .partss input {
        width: 100%;
    }

    .nd_wrap {
        display: flex;
        justify-content: center;
    }

    .margin .filer_wrap .partss:nth-child(3) {
        width: 100%;
    }

    .one_component {
        width: 150px;
        margin-bottom: 40px;
    }
}

@media (max-width: 620px) {
    .four_components {
        flex-wrap: wrap;
    }

    .prls {
        width: 190px;
        margin-bottom: 40px;
    }

    .marg_button .button_green {
        width: 75%;
    }
}

@media (max-width: 510px) {
    .nd_wrap .partss {
        width: 100%;
    }

    .nt_mt_tabs * {
        font-size: 13px;
    }

    .calc_calc {
        margin: 0 15px;
    }

    .marg_button .button_green {
        width: 100%;
    }

    #nttx .calc_input_wrap .partss.padd .mini_label {
        height: 30px;
    }

    .one_component {
        width: 100%;
        margin-left: 0;
        margin-right: 0;
        display: flex;
        justify-content: space-between;
    }

    .mobile_component {
        display: flex;
        align-items: center;
    }

    .label_text {
        margin-bottom: 0;
        position: relative;
        top: -2px;
        margin-left: 10px;
    }

    .fflag {
        margin: 0 auto;
    }

    .for_margin {
        display: flex;
        align-items: center;
    }

}

@media (max-width: 510px) {
    .calculators {
        margin: 0 0 30px;
    }

    .firsttt {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 5px;
        line-height: 2;
    }

    .fleft {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    .prls {
        width: 100%;
        display: flex;
        text-align: left;
        margin-bottom: 20px;
        align-items: center;
    }

    .all_together {
        width: 100%;
    }

    span.label {
        margin: 0;
    }

    .nt_mt_tabs {
        margin-bottom: 25px;
    }

    .__main_form {
        margin-bottom: 20px;
    }
}

.__only-nttx {
    display: none;
}

#__more_ul ul {
    display: none;
}

.non_curr_only {
    display: none;
}

.rate_changes {
    margin-bottom: 50px;
}

.rate_changes > h3 {
    font-size: 20px;
    text-align: center;
    margin-bottom: 50px;
    font-weight: 500;
}

.rate_changes table {
    width: 100%;
    text-align: center;
    border-collapse: collapse;
}

.rate_changes table td {
    width: 16.6666%;
    padding: 10px;
    box-sizing: border-box;
    font-size: 14px;
}

.hor td {
    font-weight: 500;
}

.rate_changes table tr td:first-child {
    text-align: left;
}

.rate_changes table tr .flag_24 {
    margin-left: 0;
}

.its_name {
    position: relative;
    top: -3px;
}

@media (max-width: 585px) {
    .rate_changes > h3 {
        margin-bottom: 30px;
    }

    .rate_changes table {
        width: 600px;
    }

    .table {
        overflow-x: scroll;
    }

    .rate_changes {
        margin-bottom: 0;
    }

    .rate_changes > h3 {
        margin-bottom: 30px;
    }
}


/*lang*/
.en {
    background-position: -204px -1px;
}

.ru {
    background-position: -407px -1px;
}

.es {
    background-position: -1px -22px;
}

.pt {
    background-position: -30px -22px;
}

.ar {
    background-position: -59px -22px;
}

.fa {
    background-position: -88px -22px;
}

.tr {
    background-position: -494px -1px;
}

.vi {
    background-position: -117px -22px;
}

.ja {
    background-position: -262px -1px;
}

.ID {
    background-position: -175px -22px;
}

.fr {
    background-position: -204px -22px;
}

.cs {
    background-position: -233px -22px;
}

.hi {
    background-position: -262px -22px;
}

.de {
    background-position: -291px -22px;
}

.it {
    background-position: -320px -22px;
}

.ms {
    background-position: -291px -106px;
}

.zh {
    background-position: -146px -22px;
}

.zh_cn {
    background-position: -88px -1px;
}

/*cur*/
.AED {
    background-position: -349px -22px;
}

.AMD {
    background-position: -378px -22px;
}

.ANG {
    background-position: -407px -22px;
}

.ARS {
    background-position: -436px -22px;
}

.AUD {
    background-position: -1px -1px;
}

.AWG {
    background-position: -465px -22px;
}

.AZN {
    background-position: -494px -22px;
}

.BAM {
    background-position: -523px -22px;
}

.BBD {
    background-position: -552px -22px;
}

.CAD {
    background-position: -30px -1px;
}

.CHF {
    background-position: -59px -1px;
}

.CNH {
    background-position: -88px -1px;
}

.CZK {
    background-position: -117px -1px;
}

.DKK {
    background-position: -146px -1px;
}

.EUR {
    background-position: -175px -1px;
}

.GBP {
    background-position: -204px -1px;
}

.HKD {
    background-position: -233px -1px;
}

.JPY {
    background-position: -262px -1px;
}

.MXN {
    background-position: -291px -1px;
}

.NOK {
    background-position: -320px -1px;
}

.NZD {
    background-position: -349px -1px;
}

.PLN {
    background-position: -378px -1px;
}

.RUB {
    background-position: -407px -1px;
}

.SEK {
    background-position: -436px -1px;
}

.SGD {
    background-position: -465px -1px;
}

.TRY {
    background-position: -494px -1px;
}

.USD {
    background-position: -523px -1px;
}

.ZAR {
    background-position: -552px -1px;
}

.BDT {
    background-position: -1px -43px;
}

.BGN {
    background-position: -30px -43px;
}

.BHD {
    background-position: -59px -43px;
}

.BIF {
    background-position: -88px -43px;
}

.BMD {
    background-position: -117px -43px;
}

.BND {
    background-position: -146px -43px;
}

.BOB {
    background-position: -175px -43px;
}

.BOV {
    background-position: -175px -43px;
}

.BRL {
    background-position: -204px -43px;
}

.BSD {
    background-position: -233px -43px;
}

.BTN {
    background-position: -262px -43px;
}

.BWP {
    background-position: -291px -43px;
}

.BYN {
    background-position: -320px -43px;
}

.BYR {
    background-position: -320px -43px;
}

.BZD {
    background-position: -349px -43px;
}

.CDF {
    background-position: -378px -43px;
}

.CHE {
    background-position: -59px -1px;
}

.CLF {
    background-position: -407px -43px;
}

.CLP {
    background-position: -407px -43px;
}

.CNY {
    background-position: -436px -43px;
}

.COP {
    background-position: -465px -43px;
}

.COU {
    background-position: -465px -43px;
}

.CRC {
    background-position: -494px -43px;
}

.CUC {
    background-position: -523px -43px;
}

.CUP {
    background-position: -523px -43px;
}

.CVE {
    background-position: -552px -43px;
}

.DJF {
    background-position: -1px -64px;
}

.DOP {
    background-position: -30px -64px;
}

.DZD {
    background-position: -59px -64px;
}

.EGP {
    background-position: -88px -64px;
}

.ERN {
    background-position: -117px -64px;
}

.ETB {
    background-position: -146px -64px;
}

.FJD {
    background-position: -175px -64px;
}

.FKP {
    background-position: -204px -64px;
}

.GEL {
    background-position: -233px -64px;
}

.GHS {
    background-position: -262px -64px;
}

.GIP {
    background-position: -291px -64px;
}

.GMD {
    background-position: -320px -64px;
}

.GNF {
    background-position: -349px -64px;
}

.GTQ {
    background-position: -378px -64px;
}

.GYD {
    background-position: -407px -64px;
}

.HNL {
    background-position: -436px -64px;
}

.HRK {
    background-position: -465px -64px;
}

.HTG {
    background-position: -494px -64px;
}

.HUF {
    background-position: -523px -64px;
}

.IDR {
    background-position: -175px -22px;
}

.ILS {
    background-position: -552px -64px;
}

.INR {
    background-position: -262px -22px;
}

.IQD {
    background-position: -1px -86px;
}

.IRR {
    background-position: -88px -22px;
}

.ISK {
    background-position: -30px -86px;
}

.JMD {
    background-position: -59px -86px;
}

.JOD {
    background-position: -88px -86px;
}

.KES {
    background-position: -117px -86px;
}

.KGS {
    background-position: -146px -86px;
}

.KHR {
    background-position: -175px -86px;
}

.KPW {
    background-position: -204px -86px;
}

.KRW {
    background-position: -233px -86px;
}

.KWD {
    background-position: -262px -86px;
}

.KYD {
    background-position: -291px -86px;
}

.KZT {
    background-position: -320px -86px;
}

.LAK {
    background-position: -349px -86px;
}

.LBP {
    background-position: -378px -86px;
}

.LKR {
    background-position: -407px -86px;
}

.LRD {
    background-position: -436px -86px;
}

.LSL {
    background-position: -465px -86px;
}

.LYD {
    background-position: -494px -86px;
}

.MAD {
    background-position: -523px -86px;
}

.MDL {
    background-position: -552px -86px;
}

.MGA {
    background-position: -1px -106px;
}

.MKD {
    background-position: -30px -106px;
}

.MMK {
    background-position: -59px -106px;
}

.MNT {
    background-position: -88px -106px;
}

.MOP {
    background-position: -117px -106px;
}

.MRO {
    background-position: -146px -106px;
}

.MUR {
    background-position: -175px -106px;
}

.MVR {
    background-position: -204px -106px;
}

.MWK {
    background-position: -233px -106px;
}

.MXV {
    background-position: -262px -106px;
}

.MYR {
    background-position: -291px -106px;
}

.MZN {
    background-position: -320px -106px;
}

.NAD {
    background-position: -349px -106px;
}

.NGN {
    background-position: -378px -106px;
}

.NIO {
    background-position: -407px -106px;
}

.NPR {
    background-position: -436px -106px;
}

.OMR {
    background-position: -465px -106px;
}

.PAB {
    background-position: -494px -106px;
}

.PEN {
    background-position: -523px -106px;
}

.PGK {
    background-position: -552px -106px;
}

.PHP {
    background-position: -1px -127px;
}

.PKR {
    background-position: -30px -127px;
}

.PYG {
    background-position: -59px -127px;
}

.QAR {
    background-position: -88px -127px;
}

.RON {
    background-position: -117px -127px;
}

.RSD {
    background-position: -146px -127px;
}

.RWF {
    background-position: -175px -127px;
}

.SBD {
    background-position: -204px -127px;
}

.SCR {
    background-position: -233px -127px;
}

.SDG {
    background-position: -262px -127px;
}

.SLL {
    background-position: -291px -127px;
}

.SOS {
    background-position: -320px -127px;
}

.SSP {
    background-position: -349px -127px;
}

.STD {
    background-position: -378px -127px;
}

.SVC {
    background-position: -407px -127px;
}

.SYP {
    background-position: -436px -127px;
}

.SZL {
    background-position: -465px -127px;
}

.THB {
    background-position: -494px -127px;
}

.TJS {
    background-position: -523px -127px;
}

.TMT {
    background-position: -552px -127px;
}


.TND {
    background-position: -1px -148px;
}

.TOP {
    background-position: -30px -148px;
}

.TTD {
    background-position: -59px -148px;
}

.TWD {
    background-position: -88px -148px;
}

.TZS {
    background-position: -117px -148px;
}

.UAH {
    background-position: -146px -148px;
}

.UGX {
    background-position: -175px -148px;
}

.USN {
    background-position: -523px -1px;
}

.UYI {
    background-position: -204px -148px;
}

.UYU {
    background-position: -204px -148px;
}

.UZS {
    background-position: -233px -148px;
}

.VEF {
    background-position: -262px -148px;
}

.VND {
    background-position: -117px -22px;
}

.VUV {
    background-position: -291px -148px;
}

.WST {
    background-position: -320px -148px;
}

.YER {
    background-position: -349px -148px;
}

.ZMW {
    background-position: -407px -148px;
}

.ZWL {
    background-position: -436px -148px;
}

.XCD {
    background-position: -465px -148px;
}

.SAR {
    background-position: -59px -22px;
}

.SRD {
    background-position: -494px -148px;
}

.SHP {
    background-position: -523px -148px;
}

.KMF {
    background-position: -552px -148px;
}

.XAF {
    background-position: -262px -64px;
}
.BTC {
    background-position: -436px -252px;
}

.ETH {
    background-position: -464px -252px;
}

.LTC {
    background-position: -522px -252px;
}

.XRP {
    background-position: -553px -252px;
}
.BCH {
    background-position: -494px -252px;
}


.XDR, .XOF, .XPF, .XSU, .XUA {
    background: #ccc;
}

.flag {
    position: relative;
    top: -2px;
    margin-right: 30px;
}

.flag:hover {
    cursor: pointer;
}

.flag_ {
    display: inline-block;
    /*width: 28px;*/
    height: 20px;
    background: url(/assets/img/flags_sprite.png);
    left: 0;
    box-shadow: 0 0 3px #ccc;
    border-radius: 1px;
    min-width: 28px;
}

.flag_text {
    display: inline-block;
    font-size: 12px;
    color: white;
    margin-left: 10px;
    text-transform: uppercase;
}

body[dir=rtl] .flag_text {
    margin-right: 10px;
    margin-left: 0;
}

.flag_arrow {
    display: inline-block;
    width: 16px;
    height: 16px;
    background: url(/assets/img/arrows_sprite.png) -25px 1px;
    margin-left: 10px;
}

.flag_arrow:hover,
.flag_:hover,
.flag_text:hover {
    cursor: pointer;
}

@media (max-width: 520px) {
    .flag_arrow {
        display: none;
    }
}

.flag_none {
    position: absolute;
    display: none;
    /*left: -340px;*/
    right: 3px;
    top: 35px;
    width: 560px;
    background: white;
    overflow: hidden;
    padding: 10px;
    z-index: 600;
    -webkit-transition: 2s;
    transition: 2s;
}

body[dir=rtl] .flag_none {
    right: auto;
    right: initial;
    left: 3px;
}

.flag_none > div {
    float: left;
    width: 180px;
    padding: 5px;
    box-sizing: border-box;
    /*background: green;*/
    margin: 5px 0 5px 0;
}

body[dir=rtl] .flag_none > div {
    float: right;
}

.flag_none > div:hover, .flag_none > div.active {
    background: #cce3ff;
    cursor: pointer;
}

.flag_none > div:nth-child(3n) {
    margin: 5px 0;
}

.flag_none > div > a {
    display: flex;
    align-items: center;
    color: black;
    -webkit-transition: ease 0.4s;
    transition: ease 0.4s;
    font-weight: 400;
}

.flag_none .flag_24 {
    margin: 0 8px 0 0;
}

body[dir=rtl] .flag_none .flag_24 {
    margin: 0 0 0 8px;
}

@media (max-width: 928px) {
    .flag_none {
        width: 400px;
        height: 190px;
        overflow-y: scroll;
    }
}

@media (max-width: 520px) {
    .flag_none {
        width: 210px;
    }
}

#__more_ul ul {
    display: none;
}

.more_less {
    width: 100%;
    position: relative;
    margin-top: 20px;
}

.__types {
    position: relative;
    margin: 0 auto;
    max-width: 120px;
    text-align: center;
    background: #ffffff;
    z-index: 40;
    color: #4C7B00;
}


.__types:hover,
.arrow_down:hover {
    cursor: pointer;
}

.__hide {
    display: none;
}

.expandable {
    background: #fff;
    overflow: hidden;
    color: #000;
    line-height: 100px;
    -webkit-transition: all 3s ease-in-out;
    transition: all 3s ease-in-out;
    height: 0;
}

.slade:target {
    height: 100%;;
}

#__more_ul {
    padding-bottom: 20px;
    overflow: hidden;
}

.slideNone {
    height: 0;
    overflow: hidden;
    -webkit-transition: 2s;
    transition: 2s;
}

.slide_ja {
    height: 169px;
    -webkit-transition: 2s; /* For Safari 3.1 to 6.0 */
    transition: 2s;
}

.slide_ru {
    height: 300px;
    -webkit-transition: 2s; /* For Safari 3.1 to 6.0 */
    transition: 2s;
}

.slide_en {
    height: 300px;
    -webkit-transition: 2s; /* For Safari 3.1 to 6.0 */
    transition: 2s;
}

.slide_ar {
    height: 200px;
    -webkit-transition: 2s; /* For Safari 3.1 to 6.0 */
    transition: 2s;
}

.slide_es {
    height: 300px;
    -webkit-transition: 2s; /* For Safari 3.1 to 6.0 */
    transition: 2s;
}

.slide_cs {
    height: 300px;
    -webkit-transition: 2s; /* For Safari 3.1 to 6.0 */
    transition: 2s;
}

.slide_fa {
    height: 300px;
    -webkit-transition: 2s; /* For Safari 3.1 to 6.0 */
    transition: 2s;
}

.slide_fr {
    height: 300px;
    -webkit-transition: 2s; /* For Safari 3.1 to 6.0 */
    transition: 2s;
}

.slide_hi {
    height: 260px;
    -webkit-transition: 2s; /* For Safari 3.1 to 6.0 */
    transition: 2s;
}

.slide_ID {
    height: 300px;
    -webkit-transition: 2s; /* For Safari 3.1 to 6.0 */
    transition: 2s;
}

.slide_it {
    height: 300px;
    -webkit-transition: 2s; /* For Safari 3.1 to 6.0 */
    transition: 2s;
}

.slide_ms {
    height: 300px;
    -webkit-transition: 2s; /* For Safari 3.1 to 6.0 */
    transition: 2s;
}

.slide_pt {
    height: 300px;
    -webkit-transition: 2s; /* For Safari 3.1 to 6.0 */
    transition: 2s;
}

.slide_tr {
    height: 270px;
    -webkit-transition: 2s; /* For Safari 3.1 to 6.0 */
    transition: 2s;
}

.slide_vi {
    height: 220px;
    -webkit-transition: 2s; /* For Safari 3.1 to 6.0 */
    transition: 2s;
}

.slide_zh {
    height: 200px;
    -webkit-transition: 2s; /* For Safari 3.1 to 6.0 */
    transition: 2s;
}

.slide_zh_cn {
    height: 200px;
    -webkit-transition: 2s; /* For Safari 3.1 to 6.0 */
    transition: 2s;
}

.tabs table .currensyname .flag_24 {
    position: absolute;
    left: 10px;
    top: 0;
    bottom: 0;
    margin: auto;
}

.tabs .even {
    background: #f1f1f1;
}

.__curow.__focused {
    background-color: red;
}

.tgl_btn_col {
    position: relative;
}

.buttons_cur a {
    border-radius: 0;
    padding: 4px;
    min-width: 100px;
}

.buttons_cur a span {
    font-size: 20px;
}

.arrow_to_show:hover {
    cursor: pointer;
}

.info_none {
    display: none;
    border-top: 1px solid #cccccc;
    border-bottom: 1px solid #cccccc;
}

.curr_info_td {
    overflow: hidden;
}

.curr_info_tgl {
    display: block;
    text-align: center;
}

.left_dropping_down {
    float: left;
    width: 40%;
}

.right_dropping_down {
    float: right;
    width: 410px;
}

.description-body p {
    margin: 0;
    font-size: 14px;
}

.description-body ul li {
    font-size: 14px;
}

.description-body ul {
    margin-top: 20px;
}

.flagwrap {
    position: relative;
    width: 38px;
    height: 30px;
}

.el1.flag_24,
.el2.flag_24 {
    margin: 0;
}

.el1 {
    position: absolute;
    top: 0;
    left: 0;
}

.el2 {
    position: absolute;
    bottom: 0;
    right: 0;
}

.infowrap {
    font-size: 18px;
}

.description-body p {
    margin: 0;
    font-size: 14px;
}

.description-body ul li {
    font-size: 14px;
}

.description-body ul {
    margin-top: 20px;
}

.di_content_wrap {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
}

.u_turn {
    -webkit-transform: rotate(180deg);
            transform: rotate(180deg);
}

.arrow_to_show:hover {
    cursor: pointer;
}

.arrow_to_show {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 20px;
    margin: auto;
    width: 10px;
    height: 10px;
    background: url(/assets/img/arrows_sprite.png) -27px -19px no-repeat;
    -webkit-transition: 0.2s;
    transition: 0.2s;
}

.dflag {
    width: 36%;
}

.left_text p {
    text-align: left;
}

.div_text {
    margin-bottom: 60px;
}

.div_text a:hover {
    text-decoration: underline;
    font-weight: bold;
}

.currency {
    /*font-family: Montserrat;*/
    font-weight: 500;
}

.selected {
    position: relative;
    display: flex;
    width: 100%;
    height: 46px;
    text-align: left;
    align-items: center;
}

.dflag {
    display: none;
    height: 150px;
    overflow-y: scroll;
    position: absolute;
    width: 100%;
    background: white;
    z-index: 1000;
}

.one_by_one {
    width: 100%;
    display: flex;
    align-items: center;
    box-sizing: border-box;
    height: 30px;
}

.one_by_one.active, .one_by_one:hover {
    background: #CCE3FF;
    cursor: pointer;
}

.arrow_d {
    position: absolute;
    display: inline-block;
    width: 16px;
    height: 16px;
    background: url(/assets/img/arrows_sprite.png) -24px -16px no-repeat;
    top: 0;
    right: 15px;
    bottom: 0;
    margin: auto;
}

.sections.sec3:hover {
    outline: 1px solid #4C7B00;
}

.sections.sec3:hover button span {
    background: url(/assets/img/arrows_sprite.png) 0 -24px;
}

.sections.sec3 button {
    width: 46px;
    height: 44px;
    background-color: transparent;
}

.sections.sec3 button span {
    position: relative;
    top: 1px;
    display: inline-block;
    width: 24px;
    height: 24px;
    background-image: url(/assets/img/arrows_sprite.png);
}

@media (max-width: 475px) {
    .currency {
        font-size: 16px;
    }

    .flag_24 {
        margin: 0 3px;
    }
}



.right_text {
    width: 100%;
    color: white;
    font-size: 20px;
    margin-top: 15px;
    font-weight: 300;
}

.right_button {
    width: 100%;
    box-sizing: border-box;
    margin-top: 20px;
}

.div_text {
    margin-top: 18px;
    color: white;
}

/* content  */

.three {
    width: calc(100% - 300px);
    padding-right: 60px;
}

body[dir=rtl] .three {
    padding-left: 60px;
    padding-right: 0;
}

.cur_mini_text {
    width: 100%;
}

.mini_text {
    position: relative;
    width: 100%;
    padding-left: 40px;
    box-sizing: border-box;
    margin-bottom: 20px;
}

.mini_text p {
    font-size: 14px;
}

.mini_text ul {
    display: none;
    /* piti jnjenq verjum*/
}

.mini_text span {
    position: absolute;
    top: 0;
    left: 0;
    margin: 0;
}

.click_div {
    margin-bottom: 30px;
}

.click_div a {
    width: 100%;
    font-size: 11px;
    font-weight: 500;
}
.button_green,
.button_blue {
    color: white;
}
.open_acc {
    width: 100%;
}

.open_acc h2 {
    font-weight: normal;
}

.open_button {
    width: 100%;
    margin-top: 15px;
}

.open_button .button_green {
    margin-right: 10px;
}

/*charts*/

.chart_icon {
    width: 100%;
    margin-top: 30px;
}

.left_chart {
    float: left;
}

.graph {
    display: inline-block;
    width: 24px;
    height: 24px;
    background: #d8d8d8 url(/images/header/arrows_sprite.png);
    border-radius: 3px;
    padding: 1px;
    margin-right: 5px;
}


.graph:hover,
.graph.active {
    cursor: pointer;
    background: #3d77bb url(/images/header/arrows_sprite.png);
}

.icon_graph_line {
    background-position: 0 -47px;
}

.icon_graph_line:hover,
.icon_graph_line.active {
    background-position: -23px -48px;
}

.icon_graph_candlestick {
    background-position: -1px -72px;
}

.icon_graph_candlestick:hover,
.icon_graph_candlestick.active {
    background-position: -23px -71px;
}

.icon_graph_ohlc {
    background-position: 1px -95px;
}

.icon_graph_ohlc:hover {
    background-position: -23px -96px;
}

.right_chart {
    float: right;
}

.right_chart ul {

}

.right_chart ul li {
    display: inline-block;
    border-radius: 2px;
    padding: 4px;
    margin: 0 4px;
}

.right_chart ul li.active {
    background: #3d77bb;
}

.right_chart ul li:hover {
    background: #3d77bb;
    cursor: pointer;
}

.right_chart ul li span {
    color: #3d77bb;
    font-size: 14px;
}

.right_chart ul li:hover span {
    color: white;
}

.right_chart ul li.active span {
    color: white;
}

.left_rate, .right_rate {
    width: 100%;
    /*height: 400px;*/
    overflow: hidden;
}

.rates_today h2 {
    margin-bottom: 15px;
}


.fulllist {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

.all_rates {
    font-size: 16px;
    position: relative;
    display: flex;
    width: calc(50% - 25px);
    height: 40px;
    align-items: center;
    padding: 10px;
    font-family: Montserrat;
    font-weight: 400;
    justify-content: center;
}

.all_rates:nth-child(2n+1) {
    margin-right: 50px;
}

.all_rates:nth-child(4n+2),
.all_rates:nth-child(4n+1) {
    background: #f6f6f6;
}

.exchange-rate {
    display: flex;
    align-items: center;
    width: 100px;
    justify-content: flex-end;
}

.equal-symbol {
    margin: 0 10%;
}

.exchange-rate-right {
    width: 100px;
    justify-content: flex-start;
    display: flex;
}

.all_rates>:last-child>div {
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.exchange-rate {
    cursor: pointer;
}


.exchange-rate > div {
    display: flex;
    align-items: center;
}

.__CAD.__rate {
    margin: 0 10px;
}

.curs_color {
    color: #4C7B00;
    font-weight: bold;
}

.vs_cures {
    width: 100%;
    overflow: hidden;
}

.cur1_vs_cur2 {
    float: left;
    width: calc(50% - 22.5px);
    border-bottom: 1px solid #dadada;
}

body[dir=rtl] .cur1_vs_cur2 {
    float: right;
}

.cur1_vs_cur2:first-child {
    margin-right: 45px;
}

body[dir=rtl] .cur1_vs_cur2:first-child {
    margin-left: 45px;
    margin-right: 0;
}


.some_values.head_name .cur1 {
    text-align: right;
    z-index: 2;
    justify-content: flex-end;
}

body[dir=rtl] .some_values.head_name .cur1 {
    text-align: left;
}


.some_values.head_name .cur2 {
    text-align: left;
    justify-content: flex-end;
}
body[dir=rtl] .some_values.head_name .cur2{
    text-align: right;
}

/*market is daily*/

.market_is_daily {
    position: relative;
    padding: 15px 18px;
    background: #ecf2f9;
    box-sizing: border-box;
    margin: 40px 0 50px;
    padding-bottom: 20px;
}

.market_is_daily h2 {
    margin-bottom: 10px;
}

.market_is_daily p:nth-child(1) {
    font-size: 20px;
    margin-bottom: 20px;
}

.market_is_daily p:nth-child(2) {
    max-width: 500px;
    font-size: 16px;
}


.market_is_daily div {
    position: absolute;
    bottom: 20px;
    right: 20px;
}


/*some_values*/
.some_values:nth-child(even) {
    background: #f6f6f6;
}

.some_values {
    position: relative;
    display: flex;
    justify-content: flex-start;
    width: 100%;
    height: 40px;
    padding: 10px;
    box-sizing: border-box;
    font-size: 16px;
    color: #000;
}

.some_values > span {
    flex-basis: 40%;
}
.some_values.head_name > span:nth-of-type(2),
.some_values > span.horizontal_line {
    text-align: center;
    flex-basis: 20%;
}


.__sums_block {
    text-align: left;
}
body[dir=rtl] .__sums_block{
    text-align: right;
}
/*group liders and lossers*/

.losers_leaders {
    width: 100%;
    overflow: hidden;
    padding-top: 10px;
}

.losers_leaders > div {
    overflow: hidden;
}

.losers_leaders.lead > div {
    border-left: 4px solid #32aa43;
    display: flex;
    justify-content: space-between;
}

.losers_leaders.los > div {
    border-left: 4px solid #d7262e;
    display: flex;
    justify-content: space-between;
}

.top_lose_gain_text {
    font-family: Montserrat,Georgia,sans-serif;
    /*font-size: 24px;*/
    font-weight: 400;
    color: #000;
}

.losers_leaders h3 {
    margin: 20px 0;
    font-size: 13px;
}

.one_top_los {
    float: left;
    width: 16.7%;
    padding: 0 20px;
    box-sizing: border-box;
}

.top_type, .bottom_type {
    position: relative;

    display: flex;
    justify-content: space-between;
    box-sizing: border-box;
}

.top_type {
    padding: 0 0 5px;
}

.bottom_type {
    top: 4px;
}

.top_type a {
    font-size: 12px;
}

.top_type {
    border-bottom: 1px solid gray;
}

.flag_type .flag_24 {
    margin: 0 5px 0 0;
}

.cur_rate {
    display: flex;
    align-items: center;
    font-size: 14px;
}

.cur__type {
    font-size: 14px;
    position: relative;
    top: 2px;
}

.arr_rate {
    width: 10px;
    height: 10px;
    display: inline-block;
    background: url(/assets/img/arrows_sprite.png) no-repeat;
    margin-right: 5px;
}

.to_up_sp {
    background-position: -51px -3px;
}

.to_down_sp {
    background-position: -51px -19px;
}

.losers_leaders.lead .cur_rate {
    color: #32aa43;
}

.losers_leaders.los .cur_rate {
    color: #d7262e;
}

body[dir=rtl] .select_div > div:first-child {
    margin-right: 0;
}

body[dir=rtl] .flag {
    margin-right: 0;
}

body[dir=rtl] .flag-dropdown.open-dropdown ul.country-list {
    text-align: right;
}

body[dir=rtl] .country div.flag {
    margin-left: 6px;
}

body[dir='rtl'] .arrow_d {
    left: 15px;
    right: inherit;
}

body[dir='rtl'] .left_content {
    float: right;
}

body[dir='rtl'] .right_content {
    float: left;
}

body[dir='rtl'] .left_chart {
    float: right;
}

body[dir='rtl'] .right_chart {
    float: left;
}

body[dir='rtl'] .left_rate,
body[dir='rtl'] .right_rate {
    float: right;
}

body[dir='rtl'] .market_is_daily div {
    bottom: 20px;
    left: 20px;
    right: inherit;
}


body[dir='rtl'] .losers_leaders.lead > div {
    border-left: none;
    border-right: 4px solid #32aa43;
}

body[dir='rtl'] .losers_leaders.los > div {
    border-left: none;
    border-right: 4px solid #d7262e;
}

body[dir='rtl'] .one_top_los {
    float: right;
}

body[dir='rtl'] .arr_rate {
    margin-right: 0;
    margin-left: 5px;
}

body[dir='rtl'] .flag_type .flag_24 {
    margin: 0 0 0 5px;
}


body[dir='rtl'] .pci_change_value {
    text-align: left;
}


body[dir='rtl'] .mini_text {
    padding-left: 0;
    padding-right: 40px;
}

body[dir='rtl'] .mini_text span {
    left: inherit;
    right: 0;
}


body[dir='rtl'] .open_button .button_green {
    margin-right: 0;
    margin-left: 10px;
}

@media (min-width: 929px) and (max-width: 1160px) {
    .open_button a {
        width: 90%;
        margin-bottom: 10px;
    }
}

@media (max-width: 928px) {
    .currency_box .left_box, body[dir=rtl] .currency_box .left_box {
        max-width: 650px;
        width: 100%;
        float: none;
        border-right: none;
        padding-right: 0;
        padding-left: 0;
        margin: 0 auto;
        border: 0;
        border-bottom: 1px solid #ffffff;
    }

    h2.white_h2 {
        text-align: center;
        margin-bottom: 10px;
    }

    body[dir=rtl].left_box {
        padding-left: 0;
    }

    body[dir=rtl] .currency_box .right_box {
        max-width: 520px;
        width: 100%;
        float: none;
        padding: 0;
        margin: 0 auto;
    }

    .left_text p,
    .select_text,
    .right_box > *,
    .click_div,
    .open_acc {
        text-align: center;
    }

    .select_text {
        margin-bottom: 25px;
    }

    body[dir='rtl'] .select_text {
        text-align: center;
    }

    .div_text {
        margin-bottom: 60px;
    }

    .one {
        width: 100%;
        padding: 0;
    }

    body[dir='rtl'] .content .three {
        margin: 0 auto;
        max-width: none;
        max-width: initial;
        padding-left: 0;
    }

    .two {
        margin: 0 auto;
        max-width: inherit;
    }

    .see {
        text-align: center;
    }

    .open_acc {
        margin-bottom: 30px;
    }

    .market_is_daily p:nth-child(1) {
        font-size: 18px;
    }

    .one_top_los {
        width: 48%;
    }

    .one_top_los:nth-child(2) {
        margin-bottom: 20px;
    }
}


@media (max-width: 680px) {
    .my_wrapper .rates_today {
        display: block;
    }

    .left_rate, .right_rate {
        width: 100%;
    }

    .left_rate {
        margin-bottom: 20px;
    }

    .market_is_daily p:nth-child(1) {
        text-align: center;
    }

    .market_is_daily p:nth-child(2) {
        text-align: center;
        margin-bottom: 20px;
    }

    .market_is_daily div {
        position: static;
        text-align: center;
    }

    .one_top_los {
        padding: 0 10px;
    }
}


@media (max-width: 520px) {
    .sections.sec1 {
        width: 100%;
        margin-bottom: 20px;
        margin-right: 0;
    }
    body[dir=rtl] .select_div > div.sec1{
        margin-left: 0;
    }
    .sections.sec2 ,
    .sections.sec4{
        width: calc(50% - 33px);
    }

    .sections.sec1 input {
        width: 100%;
    }

    .all_rates,
    .some_values {
        font-size: 16px;
    }

    .h1_title {
        width: 280px;
        margin: 0 auto;
        font-size: 26px;
    }

    .open_button {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .open_button a {
        margin-bottom: 10px;
    }

    .open_button .button_green {
        margin-right: 0;
    }

    body[dir='rtl'] .open_button .button_green {
        margin-left: 0;
        margin-right: 0;
    }
}


@media (max-width: 375px) {
    .losers_leaders.lead > div,
    .losers_leaders.los > div {
        border-left: none;
    }

    .one_top_los {
        width: 100%;
        margin-bottom: 20px;
    }

    .losers_leaders.lead .one_top_los {
        border-left: 4px solid #32aa43;
    }

    .losers_leaders.los .one_top_los {
        border-left: 4px solid #ff0000;
    }

    .all_rates:nth-child(2n) {
        /*margin-left: 8%;*/
    }


    .all_rates {
        width: 45%;
    }

    .all_rates,
    .vs_cures .some_values,
    .some_values .exchange-rate {
        font-size: 12px;
    }
}

.fndzyau {
    background-color: red;
    height: 100px;
}


.LineChart {
    background: url(/assets/img/arrows_sprite.png) 0px -49px no-repeat;
}

.LineChart:hover {
    background: url(/assets/img/arrows_sprite.png) -25px -49px no-repeat;
}

.activeLine {
    background: url(/assets/img/arrows_sprite.png) -25px -49px no-repeat;
    background-color: #505edf;
    border-radius: 5px;
}

.CalumChart {
    background: url(/assets/img/arrows_sprite.png) 0px -73px no-repeat;
}

.CalumChart:hover {
    background: url(/assets/img/arrows_sprite.png) -25px -73px no-repeat;
}

.activeCalum {
    background: url(/assets/img/arrows_sprite.png) -25px -73px no-repeat;
    background-color: #505edf;
    border-radius: 5px;
}

.OHLCSeries {
    background: url(/assets/img/arrows_sprite.png) 0px -97px no-repeat;
}

.OHLCSeries:hover {
    background: url(/assets/img/arrows_sprite.png) -25px -97px no-repeat;
}

.activeOHLCS {
    background: url(/assets/img/arrows_sprite.png) -25px -97px no-repeat;
    background-color: #505edf;
    border-radius: 5px;
}

.logo_img {
    margin: 70px 0 60px;
    text-align: center;
}

.logo_img > img {
    width: 200px;
}

.img_404 {
    width: 200px;
    margin: 0 auto 30px;
    padding: 0 20px 30px;
    border-bottom: 1px solid #8cc63f;
    background: url(/assets/img/banner/imogi.png);
}

.img_404 > img {
    width: 215px;
    background: url(/assets/img/banner/404_logo.png);

}

.text {
    width: 100%;
    max-width: 600px;
    margin: 0 auto 40px;
    text-align: center;
    font-size: 18px;
    color: #4a4a4a;
}

.button {
    text-align: center;
}

/* FONT montserrat */

/* cyrillic-ext */
@font-face {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 200;
    font-display: swap;
    src: local('Montserrat ExtraLight'), local('Montserrat-ExtraLight'), url(https://fonts.gstatic.com/s/montserrat/v12/JTURjIg1_i6t8kCHKm45_aZA3gTD_u50.woff2) format('woff2');
    unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}

/* cyrillic */
@font-face {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 200;
    font-display: swap;
    src: local('Montserrat ExtraLight'), local('Montserrat-ExtraLight'), url(https://fonts.gstatic.com/s/montserrat/v12/JTURjIg1_i6t8kCHKm45_aZA3g3D_u50.woff2) format('woff2');
    unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}

/* vietnamese */
@font-face {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 200;
    font-display: swap;
    src: local('Montserrat ExtraLight'), local('Montserrat-ExtraLight'), url(https://fonts.gstatic.com/s/montserrat/v12/JTURjIg1_i6t8kCHKm45_aZA3gbD_u50.woff2) format('woff2');
    unicode-range: U+0102-0103, U+0110-0111, U+1EA0-1EF9, U+20AB;
}

/* latin-ext */
@font-face {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 200;
    font-display: swap;
    src: local('Montserrat ExtraLight'), local('Montserrat-ExtraLight'), url(https://fonts.gstatic.com/s/montserrat/v12/JTURjIg1_i6t8kCHKm45_aZA3gfD_u50.woff2) format('woff2');
    unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

/* latin */
@font-face {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 200;
    font-display: swap;
    src: local('Montserrat ExtraLight'), local('Montserrat-ExtraLight'), url(https://fonts.gstatic.com/s/montserrat/v12/JTURjIg1_i6t8kCHKm45_aZA3gnD_g.woff2) format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

/* cyrillic-ext */
@font-face {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 300;
    font-display: swap;
    src: local('Montserrat Light'), local('Montserrat-Light'), url(https://fonts.gstatic.com/s/montserrat/v13/JTURjIg1_i6t8kCHKm45_cJD3gTD_u50.woff2) format('woff2');
    unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}

/* cyrillic */
@font-face {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 300;
    font-display: swap;
    src: local('Montserrat Light'), local('Montserrat-Light'), url(https://fonts.gstatic.com/s/montserrat/v13/JTURjIg1_i6t8kCHKm45_cJD3g3D_u50.woff2) format('woff2');
    unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}

/* vietnamese */
@font-face {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 300;
    font-display: swap;
    src: local('Montserrat Light'), local('Montserrat-Light'), url(https://fonts.gstatic.com/s/montserrat/v13/JTURjIg1_i6t8kCHKm45_cJD3gbD_u50.woff2) format('woff2');
    unicode-range: U+0102-0103, U+0110-0111, U+1EA0-1EF9, U+20AB;
}

/* latin-ext */
@font-face {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 300;
    font-display: swap;
    src: local('Montserrat Light'), local('Montserrat-Light'), url(https://fonts.gstatic.com/s/montserrat/v13/JTURjIg1_i6t8kCHKm45_cJD3gfD_u50.woff2) format('woff2');
    unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

/* latin */
@font-face {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 300;
    font-display: swap;
    src: local('Montserrat Light'), local('Montserrat-Light'), url(https://fonts.gstatic.com/s/montserrat/v13/JTURjIg1_i6t8kCHKm45_cJD3gnD_g.woff2) format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

/* cyrillic-ext */
@font-face {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: local('Montserrat Regular'), local('Montserrat-Regular'), url(https://fonts.gstatic.com/s/montserrat/v12/JTUSjIg1_i6t8kCHKm459WRhyzbi.woff2) format('woff2');
    unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}

/* cyrillic */
@font-face {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: local('Montserrat Regular'), local('Montserrat-Regular'), url(https://fonts.gstatic.com/s/montserrat/v12/JTUSjIg1_i6t8kCHKm459W1hyzbi.woff2) format('woff2');
    unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}

/* vietnamese */
@font-face {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: local('Montserrat Regular'), local('Montserrat-Regular'), url(https://fonts.gstatic.com/s/montserrat/v12/JTUSjIg1_i6t8kCHKm459WZhyzbi.woff2) format('woff2');
    unicode-range: U+0102-0103, U+0110-0111, U+1EA0-1EF9, U+20AB;
}

/* latin-ext */
@font-face {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: local('Montserrat Regular'), local('Montserrat-Regular'), url(https://fonts.gstatic.com/s/montserrat/v12/JTUSjIg1_i6t8kCHKm459Wdhyzbi.woff2) format('woff2');
    unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

/* latin */
@font-face {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: local('Montserrat Regular'), local('Montserrat-Regular'), url(https://fonts.gstatic.com/s/montserrat/v12/JTUSjIg1_i6t8kCHKm459Wlhyw.woff2) format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

/* cyrillic-ext */
@font-face {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-display: swap;
    src: local('Montserrat Medium'), local('Montserrat-Medium'), url(https://fonts.gstatic.com/s/montserrat/v13/JTURjIg1_i6t8kCHKm45_ZpC3gTD_u50.woff2) format('woff2');
    unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}

/* cyrillic */
@font-face {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-display: swap;
    src: local('Montserrat Medium'), local('Montserrat-Medium'), url(https://fonts.gstatic.com/s/montserrat/v13/JTURjIg1_i6t8kCHKm45_ZpC3g3D_u50.woff2) format('woff2');
    unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}

/* vietnamese */
@font-face {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-display: swap;
    src: local('Montserrat Medium'), local('Montserrat-Medium'), url(https://fonts.gstatic.com/s/montserrat/v13/JTURjIg1_i6t8kCHKm45_ZpC3gbD_u50.woff2) format('woff2');
    unicode-range: U+0102-0103, U+0110-0111, U+1EA0-1EF9, U+20AB;
}

/* latin-ext */
@font-face {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-display: swap;
    src: local('Montserrat Medium'), local('Montserrat-Medium'), url(https://fonts.gstatic.com/s/montserrat/v13/JTURjIg1_i6t8kCHKm45_ZpC3gfD_u50.woff2) format('woff2');
    unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

/* latin */
@font-face {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-display: swap;
    src: local('Montserrat Medium'), local('Montserrat-Medium'), url(https://fonts.gstatic.com/s/montserrat/v13/JTURjIg1_i6t8kCHKm45_ZpC3gnD_g.woff2) format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

/* cyrillic-ext */
@font-face {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 700;
    font-display: swap;
    src: local('Montserrat Bold'), local('Montserrat-Bold'), url(https://fonts.gstatic.com/s/montserrat/v12/JTURjIg1_i6t8kCHKm45_dJE3gTD_u50.woff2) format('woff2');
    unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}

/* cyrillic */
@font-face {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 700;
    font-display: swap;
    src: local('Montserrat Bold'), local('Montserrat-Bold'), url(https://fonts.gstatic.com/s/montserrat/v12/JTURjIg1_i6t8kCHKm45_dJE3g3D_u50.woff2) format('woff2');
    unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}

/* vietnamese */
@font-face {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 700;
    font-display: swap;
    src: local('Montserrat Bold'), local('Montserrat-Bold'), url(https://fonts.gstatic.com/s/montserrat/v12/JTURjIg1_i6t8kCHKm45_dJE3gbD_u50.woff2) format('woff2');
    unicode-range: U+0102-0103, U+0110-0111, U+1EA0-1EF9, U+20AB;
}

/* latin-ext */
@font-face {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 700;
    font-display: swap;
    src: local('Montserrat Bold'), local('Montserrat-Bold'), url(https://fonts.gstatic.com/s/montserrat/v12/JTURjIg1_i6t8kCHKm45_dJE3gfD_u50.woff2) format('woff2');
    unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

/* latin */
@font-face {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 700;
    font-display: swap;
    src: local('Montserrat Bold'), local('Montserrat-Bold'), url(https://fonts.gstatic.com/s/montserrat/v12/JTURjIg1_i6t8kCHKm45_dJE3gnD_g.woff2) format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

.mini_text .no-abs-text span {
    position: unset;
}

g[aria-labelledby=id-79-title] {
    display: none;
}

.cur__name {
    font-size: 12px;
    color: #2f5a8d;
    cursor: pointer;
}


.twos {
    width: 360px;
    float: right;
}

body[dir=rtl] .twos {
    float: left;
}

.description-body .ul-tick li{
    line-height: normal;
    line-height: initial;
    padding-left: 0;
    padding-left: initial;
    color: #000;
    color: initial;
    font-size: 14px;
}

body[dir=rtl] .title_acc_head,
body[dir=rtl] .ul-tick li {
    padding-right: 40px;
    padding-left: 40px;
    text-align: right;
}

.description-body .ul-tick li:before{
    content: none;
}

body[dir=rtl] .ul-tick li:before {
    right: 0;
    left: auto;
}

.css-1hwfws3{
    height: auto;
}

.trd_acc_right_box .field:focus {
    border: none;
    box-shadow: none;
    outline-color: snow;
}

.css-1okebmr-indicatorSeparator {
    display: none;
}

.form-group .react-tel-input .flag-dropdown:hover .selected-flag {
    background-color: #f7f7f7;
}


.rates_today {
    width: 100%;
    overflow: hidden;
    display: flex;
    justify-content: space-between;
    padding: 0px 0px 0px 0px;
    margin-bottom: 50px;
}

.size_900 {
    max-width: 900px;
    margin-left: auto;
    margin-right: auto;
}

.bradcrumbs {
    left: 10px;
    right: 10px;
    font-size: 12px;
}

@media (max-width: 375px) {
    .bradcrumbs {
        font-size: 11px;
        top: -35px;
    }
}

.chart_active_period {
    background-color: #3d77bb !important;
    color: white !important;
}

.bannerImage {
    height: 250px;
    width: 300px;
}
.ads > a {
    margin-bottom: 10px;
}

.webinar_EN {
    background: url(/assets/img/banner/webinar-Crypto-CFD-300x250-EN.png);
}

.webinar_FA {
    background: url(/assets/img/banner/webinar-Crypto-CFD-300x250-FA.png);
}

.up {
    background: url(/assets/img/banner/UP2-CFDRates-300x250_EN.png)
}

.banner-autochartist {
    background: url(/assets/img/banner/autochartist-300x250.png)
}

.up-rtl {
    background: url(/assets/img/banner/UP2-CFDRates-300x250_FA.png)
}

.crypto-trading-banner-EN {
    background: url(/assets/img/banner/crypto-trading-300x250-EN.png)
}

.crypto-trading-banner-FA {
    background: url(/assets/img/banner/crypto-trading-300x250-FA.png)
}

.invest-banner-EN {
    background: url(/assets/img/banner/invest-300x250-EN.png)
}

.invest-banner-FA {
    background: url(/assets/img/banner/invest-300x250-FA.png)
}

.blog {
    background: url(/assets/img/banner/Blog-ForexGrad.png)
}
.new_banner {
    background: url(/assets/img/banner/pashootan-2021-300x250.png)
}
.banner_EN {
    background: url(/assets/img/banner/Banner-300x250-EN.jpg)
}

.banner_AR {
    background: url(/assets/img/banner/Banner-300x250-AR.jpg)
}

.banner_CH {
    background: url(/assets/img/banner/Banner-300x250-CH.jpg)
}

.banner_ES {
    background: url(/assets/img/banner/Banner-300x250-ES.jpg)
}

.banner_FA {
    background: url(/assets/img/banner/Banner-300x250-FA.jpg)
}

.banner_FR {
    background: url(/assets/img/banner/Banner-300x250-FR.jpg)
}

.banner_HI {
    background: url(/assets/img/banner/Banner-300x250-HI.jpg)
}

.banner_JP {
    background: url(/assets/img/banner/Banner-300x250-JP.jpg)
}

.banner_PT {
    background: url(/assets/img/banner/Banner-300x250-PT.jpg)
}

.banner_RU {
    background: url(/assets/img/banner/Banner-300x250-RU.jpg)
}

.banner_TR {
    background: url(/assets/img/banner/Banner-300x250-TR.jpg)
}

.banner_TW {
    background: url(/assets/img/banner/Banner-300x250-TW.jpg)
}

.banner_VN {
    background: url(/assets/img/banner/Banner-300x250-VN.jpg)
}

.d-none {
    display: none;
}

.advertising {
    width: 100%;
    height: 250px;
    display: flex;
    flex-direction: column;
    text-align: center;
}

.advertising .ads {
    display: inline-block;
    margin-bottom: 10px;
}

.two .open_acc,
.twos .open_acc {
    margin-bottom: 40px;
}

.open_acc .open_button a {
    max-width: 175px;
    width: 100%;
}

/*phrase*/
.giant_market {
    background-image: -webkit-gradient(linear, left top, right top, from(#f1f1f1), color-stop(#eaeaea), color-stop(#e4e4e4), color-stop(#ddd), to(#d7d7d7));
    background-image: -webkit-linear-gradient(left, #f1f1f1, #eaeaea, #e4e4e4, #ddd, #d7d7d7);
    background-image: linear-gradient(to right, #f1f1f1, #eaeaea, #e4e4e4, #ddd, #d7d7d7);
    margin-bottom: 50px;
}

.giant_market {
    display: flex;
    width: 100%;
    padding-right: 10%;
    padding-left: 10%;
    justify-content: space-between;
    align-items: center;
}

.giant_market .support_girl {
    font-family: Montserrat;
    font-size: 46px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 345px;
    height: 250px;
    background-image: url(/assets/img/banner/giant_market.png);
    background-repeat: no-repeat;
    color: #fff;
}

.giant_market h2 {
    width: 100%;
    max-width: 445px;
    font-family: Roboto,sans-serif;
    font-size: 30px;
    font-weight: 300;
    display: inline-block;
    margin: 0;
    margin-bottom: 15px;
    color: #000;
}

.txt_afisha {
    font-family: Montserrat,sans-serif;
    font-size: 18px;
    width: 100%;
    max-width: 445px;
    margin-bottom: 20px;
    text-align: left;
    color: #4a4a4a;
}

body[dir=rtl] .txt_afisha {
    text-align: right;
}

.giant_market .block_buttons {
    display: flex;
    align-items: center;
}

.giant_market .button_blue {
    font-weight: 300;
}
.topCryptoTable{
    text-align: right;
    width: 100%;
    border-left: 1px solid #eaedf3;
    border-right: 1px solid #eaedf3;
    border-bottom: 1px solid #eaedf3;
}
body[dir="rtl"] .topCryptoTable{
    text-align: left;
}
.topCryptoTable thead th:not(:last-of-type) {
    border-right: 1px solid #eaedf3;
}
body[dir="rtl"] .topCryptoTable thead th:not(:last-of-type){
    border-right: 0;
    border-right: initial;
    border-left: 1px solid #eaedf3;
}
.topCryptoTable thead th {
    vertical-align: bottom;
}
.topCryptoTable th, .table td {
    padding: 0.75rem;
    vertical-align: top;
    border-top: 1px solid #eaedf3;
}
.topCryptoTable thead > tr > th, table > thead > tr > td, table .header-table th {
    font-weight: 500;
}
.topCryptoTable thead > tr > th, table > thead > tr > td, table .header-table th {
    position: relative;
    box-sizing: border-box;
    text-indent: 0;
    color: #000;
    background: #fff !important;
}
.crypto-rate-stat .table thead th{
    color: #2380ed;
    font-weight: bold;
}
.topCryptoTable > thead {
    border-bottom: 1px solid #eaedf3;
}
.topCryptoTable > tbody > tr:nth-child(even) > td {
    background: #f6f6f6;
}
.d-flex{
    display: flex;
}
.align-center{
    align-items: center;
}
.overflow{
    overflow: auto;
}

.amount-profit{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.profit-loss-block .calculated-amount, #calculated-amount-modal {
    border: solid 6px #f4f6fa;
    border-radius: 50%;
    max-width: -webkit-fit-content;
    max-width: -moz-fit-content;
    max-width: fit-content;
    font-size: 2.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: bold;
    min-width: 115px;
    min-height: 115px;
    margin-bottom: 25px;
}


@media (max-width: 1060px) {
    #wants_to_subscribe_corporate {
        justify-content: flex-start;
    }
}

@media (max-width: 1024px) {
    .giant_market {
        padding-right: 5%;
        padding-left: 5%;
    }
}

@media (max-width: 992px) {
    .giant_market h2 {
        margin-bottom: 20px;
        text-align: center;
    }
}


@media (max-width: 900px) {

    .giant_market .support_girl {
        display: none;
        background-image: none;
    }

    .smart_trading_cont {
        position: static;
        position: initial;
    }

    .giant_market h2,
    .giant_market .block_buttons {
        justify-content: center;
    }

    .giant_market {
        padding: 20px 10px;
        justify-content: center;
    }

    .txt_afisha {
        margin-right: auto;
        margin-left: auto;
        text-align: center;
    }
}

@media (max-width: 550px) {
    .giant_market div {
        width: 100%;
        justify-content: space-between;
    }

    .items.calculatorInfo>div>span:last-child{
        font-size: 24px;
    }

    .txt_afisha {
        margin: 0 auto 20px;
        text-align: center;
    }

    .giant_market .button_blue,
    .giant_market .button_green {
        line-height: 1;
        display: flex;
        min-width: auto;
        align-items: center;
        justify-content: center;
    }
}

@media (max-width: 450px) {
    .giant_market div {
        flex-direction: column;
    }

    .giant_market div > a:first-child {
        margin: 0 0 10px;
    }

    .giant_market .button_blue,
    .giant_market .button_green {
        justify-content: center;
    }

    .giant_market div {
        width: auto;
    }

    body[dir=rtl] .giant_market .button_green {
        margin-left: 0;
    }
}

@media (max-width: 370px) {
    .giant_market .btn-lg {
        padding: 0 20px;
    }
}

.bg-banner {
    margin-top: 60px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 250px;
    background-image: url(/assets/img/banner/banner-bg.jpg);
    background-repeat: no-repeat;
    color: #fff;
    padding: 10px 40px;
}

@media (max-width: 992px) {
    .bg-banner {
        flex-direction: column;
        padding: 20px 16px;
    }
    .head-start-trading h2 {
        text-align: center;
    }
}

.head-start-trading h2 {
    margin: 0 6px 16px;
}
.head-start-trading .btn-block a{
    margin: 0 6px;
}

.btn-block {
    display: flex;
}

/*END phrase*/

@media (max-width: 1060px) {
    .trd_acc_left_box,
    .trd_acc_right_box {
        width: auto;
    }

    .trd_acc_left_box {
        margin-bottom: 30px;
    }

    .trd_acc_right_box .field {
        margin-left: 0;
    }

    body[dir=rtl] .trd_acc_right_box .field {
        margin-right: 0;
    }

}

@media (max-width: 1160px) {
    .three {
        width: 100%;
    }

    .all_rates .exchange-rate-right {
        flex-basis: 40%;
    }

    .all_rates .exchange-rate {
        flex-basis: 25%;
    }
}

@media (max-width: 942px) {
    #wants_to_subscribe_corporate button {
        width: 100%;
    }

    .trd_acc_right_box .field {
        max-width: 100%;
    }

    .open_trd_acc {
        flex-direction: column;
        align-items: center;
    }

    .trd_acc_left_box,
    .trd_acc_right_box {
        padding: 0 !important;
        border: none !important;
    }


    .conversion {
        flex-direction: column;
    }

    .three {
        width: 100%;
        padding: 0;
    }
}

@media (max-width: 768px) {
    body[dir=rtl] .all_rates:nth-child(2n+1) {
        margin-left: 0;
        margin-right: 0;
    }

    .left_rate,
    .right_rate {
        width: 100%;
    }

    .fulllist {
        margin-bottom: 20px;
    }

    .all_rates {
        width: 100%;
    }

    .all_rates:nth-child(4n+1),
    .all_rates:nth-child(4n+2) {
        background: none;
    }

    .all_rates:nth-child(odd) {
        background: #f6f6f6;
    }

    .all_rates:nth-child(2n+1) {
        margin: 0;
    }

    .cur1_vs_cur2 {
        width: 100%;
    }

    .cur1_vs_cur2:first-child {
        margin: 0 0 30px;
    }

}

@media (max-width: 576px){
    .open_trd_acc h2 {
        margin-bottom: 30px;
    }
}

@media (min-width: 576px){
    .d-sm-block {
        display: block!important;
    }

    .d-sm-none {
        display: none!important;
    }
}

@media (max-width: 550px) {
    .ul-tick li {
        font-size: 14px;
        line-height: 20px;
        margin-bottom: 10px;
    }

    .content {
        padding: 0 15px;
    }

    .all_rates .exchange-rate {
        flex-basis: 30%;
    }

    .all_rates .exchange-rate-right {
        flex-basis: 48%;
    }
}
.no-pointer{
    pointer-events: none !important;
}

@media (max-width: 375px) {

    .all_rates {
        display: flex;
        justify-content: space-between;
    }
    .all_rates .equal-symbol{
        padding: 0;
    }
}
