/*.tab-bar {*/
    /*height: 48px;*/
    /*display: flex;*/
    /*justify-content: center;*/
    /*align-items: stretch;*/
/*}*/

.tab-panel {
    margin-top: 1rem;
}

.tab-bar {
    display: flex;
    justify-content: center;
    align-items: stretch;
    position: relative;
    padding-bottom: 30px;
    /*float: right;*/
}